import React, { Component } from "react";
import { Divider, Grid, Paper, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";
import {Table} from '@material-ui/core'
import UbaGalleryPage from "./UbaGallery";

const initialState = [];
class UbaDescription extends Component {
  render() {
    return (
      <div>
        
        {this.props.type == "activity" ? <NotificationDescription type="uba" code="IQAC_UBA" title="UBA Activity" /> : null}
        {this.props.type == "gallery" ? <UbaGalleryPage type="uba" code="IQAC_UBA_GALLERY" title="UBA Gallery" /> : null}
       
      </div>
    );
  }
}

export default UbaDescription;



