import React, { Component } from 'react'
import { MDBContainer } from 'mdbreact';
import TitleStyle from '../TitleStyle';

export default class FacilitiesPage extends Component {
  render() {
    return (
      <div>
          <MDBContainer>
              <TitleStyle title={`Facilities`}
              />
             {
                this.props.data.facilities.map((el, index) =>
                <div>
                     <p align='justify' key={index}>
                    {el.title}
                  </p>
                  
                  <p align='justify' key={index}>
                    {el.description}  
                  </p>
                </div>
                 
                )
              }
          </MDBContainer>
      </div>
    )
  }
}
