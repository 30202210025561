import React, { Component } from "react";
import { Divider, Grid, Paper, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";
import {Table} from '@material-ui/core'
import IicGalleryPage from "./IicGallery";

const initialState = [];
class IicDescription extends Component {
  render() {
    return (
      <div>
        
        {this.props.type == "activity" ? <NotificationDescription type="iic" code="IQAC_IIC" title="IIC" /> : null}
        {this.props.type == "gallery" ? <IicGalleryPage type="iic" code="IQAC_IIC_GALLERY" title="IIC Gallery" /> : null}
        {this.props.type == "certificate" ? <Certitificate  /> : null}
       
      </div>
    );
  }
}

export default IicDescription;


export function Certitificate() {
  return (
    <div style={{paddingTop: 30}}>
      <Typography variant="h5">IIC Certificate</Typography>
      <br />
      <iframe width="100%" height="800px" src="/pdf/iic-certificate.pdf"></iframe>
      <br />
      <iframe width="100%" height="800px" src="/pdf/RatingCertificate_2021-22.pdf"></iframe>
      <br />
      <iframe width="100%" height="800px" src="/pdf/IIC_Appreciation_Letter_AY_2021_22.pdf"></iframe>
    </div>
  )
}

