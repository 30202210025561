import { Typography } from '@material-ui/core';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import React, { PureComponent } from 'react'

class CommitteeDescription extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoaded: false
        }
    }


    __loadFile = () => {

        this.setState({
            data: this.props.data,
            isLoaded: true,
        }, () => {
            //console.log(this.state);
        })
    }

    componentDidMount() {
        this.__loadFile();
    }

    // componentDidMount() {
    //     this.setState({
    //         data: this.props.data,
    //         isLoaded: true,
    //     })
    // }

    render() {

        return (<>
            {this.state.isLoaded ? <div>
                <h1>{this.state.data.name}</h1>
                <br />

                <p dangerouslySetInnerHTML={{__html: this.state.data.description}}></p>
                
                {Array.isArray(this.state.data.members) && this.state.data.members.length > 0 && <><h4>Members of {this.state.data.name}</h4>
                <ul>
                    {this.state.data.members.map((el) => (
                        <li><b>{el.member_name}</b>, {el.member_designation}</li>
                    ))
                    }
                </ul>
                </>}
            </div> : ''
            }</>
        )
    }
}

export default CommitteeDescription


// <h1>{this.props.data.name}</h1>
//             <br/>
//             <h4>Members of {this.props.data.name}</h4>
//             <ul>
//                 {this.props.data.members.map((el) => (
//                     <li>{el.member_name}</li>
//                 ))
//                 }
//             </ul>


