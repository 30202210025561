import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class VisionPage extends Component {
    render() {
        return (
            <div>
                <Container>
                    

                    <br />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: this.props.data.vision }}
                    style={{textAlign:'justify'}}>

                    </Typography>
                </Container>
            </div>
        );
    }
}

export default VisionPage;