import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'

export default class NIRF extends Component {

  state = {
    data: [],
    isLoaded: false
  }

  __loadFile = () => {
    let d = {
      dept_code: "DISPUR",
      type: "NIRF"
    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true,
        })
      })
  }

  componentDidMount() {
    this.__loadFile();
  }

  render() {
    return (
      <div className="my-4">
        <Container style={{minHeight: '80vh'}}>
          <div className="row">
            
            {console.log(this.state.data)} 
            <div className="col-md-12 pl-2 py-2">
              <h1>NIRF</h1>
              {this.state.isLoaded && this.state.data.length > 0 && <div>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>File</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(this.state.data) && this.state.data.map((el,index) => <TableRow key={index}>
                        <TableCell>{index+1}</TableCell>
                        <TableCell>{el.title}</TableCell>
                        <TableCell>{Array.isArray(el.upload_info) && el.upload_info.length > 0 && <a target="_blank" href={el.upload_info[0].url}>Click Here</a>}</TableCell>
                        </TableRow>)}
                    </TableBody>
                  </Table>
                </div>}
              {/* {this.state.isLoaded && this.state.data.length > 0 && this.state.data[0].upload_info.length > 0 &&
                <iframe src={this.state.data[0].upload_info[0].url} style={{width:'100%',height:'60vh',marginTop:'2em'}}></iframe>
              } */}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
