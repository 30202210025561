import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import { Alert, Button, Input, Modal, Result, message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../api/helper";



export default function VisitorsNoteContainer(){

  const [state, setState] = React.useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    isSubmitting: false,
    captcha: false,
  })

  const captchaRef = React.useRef(null)



  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [error, setError] = React.useState({
    name: "",
    contact: "",
    email: "",
    message: "",
    captcha: "",
  })

  function onClear(){
    setState({
      name: "",
      contact: "",
      email: "",
      message: "",
      isSubmitting: false,
      captcha: false,
    })
  }

  function onChange(name, value){
    setState({
      ...state,
      [name]: value,
    });
  };

  function handleValidation(){
    let isValid = true;
    let err = [];

    if (state.name == "" || state.name == null) {
      isValid = false;
      err["name"] = "Mandatory Field";
    } else {
      err["name"] = "";
    }

    if (state.email == "" || state.email == null) {
      isValid = false;
      err["email"] = "Mandatory Field";
    } else {
      err["email"] = "";
    }

    if (state.message == "" || state.message == null) {
      isValid = false;
      err["message"] = "Mandatory Field";
    } else {
      err["message"] = "";
    }

    setError({...err});
    return isValid;
  };

  function onSubmit(){
    if (handleValidation()) {
      setIsSubmitting(true)

      //const token = captchaRef.current.getValue();
        

        //if(token){

          // POST

          PostData(`/addvisitornote`, {...state})
          .then((resp) => {
            onClear();
            Modal.success({
              title: "Successfully Submitted",
              content: <>
                <Result
                   status="success"
                   title="Thank you submitting your note."
                   subTitle="We will reach if we anything."
                />
              </>
            })
            captchaRef.current.reset();
            setIsSubmitting(false)
          }).catch((err) => {
            setIsSubmitting(false)
          })


          
        //}
    }
  };



  return (
    <div>
     
      <Container style={{ minHeight: "80vh" }}>
        <br />

      
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Visitor's Note
        </Typography>

        <br />
        <Alert
          type="info"
          banner
          message={
            <>
              Hello Visitor, You can leave your message here using the
              following form. Your valuable suggessions are much appreciated.
              We will contact you if we need more information on this regard
            </>
          }
        />

        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your Full Name <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>
              {error.name}
            </div>
            <Input
              name="name"
              value={state.name}
              onChange={(e) => onChange("name", e.target.value)}
              placeholder="Full Name"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>
              Your Email ID for Communication{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>
              {error.name}
            </div>
            <Input
              type="email"
              name="email"
              value={state.email}
              onChange={(e) => onChange("email", e.target.value)}
              placeholder="Email ID"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ fontWeight: 700 }}>Your Contact Number</div>
            <div style={{ fontSize: 12, color: "red" }}>
              {error.phone}
            </div>
            <Input
              type="text"
              name="phone"
              value={state.phone}
              prefix="+91"
              onChange={(e) => onChange("phone", e.target.value)}
              placeholder="Contact Number"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ fontWeight: 700 }}>
              Your Message <span style={{ color: "red" }}>*</span>
            </div>
            <div style={{ fontSize: 12, color: "red" }}>
              {error.message}
            </div>
            <Input.TextArea
              name="message"
              value={state.message}
              onChange={(e) => onChange("message", e.target.value)}
              placeholder="Write Your Message here"
            />

            <br />
            <br />

            <ReCAPTCHA sitekey={SITE_KEY}  ref={captchaRef} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: "right" }}
          >
            <Button type="primary" loading={isSubmitting} onClick={() => onSubmit()}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
