import React, { Component } from 'react'
import { Breadcrumb, BreadcrumbItem } from 'mdbreact';

const style = {
    height: '30vh',
    background: `url('/images/office/bread.png')`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: '7vw',
    color: 'white',
    textAlign: 'left'
}

const styleBread = {
    background: 'none'
}

export default class BreadCumb extends Component {
    render() {
        return (
            <div style={style}>
                <h3 style={{ paddingLeft: '1vw', color: 'white' }}>{this.props.title}</h3>
                <Breadcrumb tag="nav"  style={styleBread} >
                    {this.props.data.map((el, index) => {
                        if(el.isActive){
                            return <BreadcrumbItem key={index} active tag="span" className="text-danger">{el.title}</BreadcrumbItem>
                        }else{
                            return <BreadcrumbItem key={index} tag="a" href={el.path}>{el.title}</BreadcrumbItem>
                        }
                    })}
                </Breadcrumb>
            </div>
        )
    }
}

