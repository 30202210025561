import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import TitleStyle from '../TitleStyle';
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Grid, CardContent, CardActions, Button } from '@material-ui/core';
import { PostData } from '../../api/service';
import Icon from '@material-ui/core/Icon'
import ShowImage from './ShowImage';



export default class Alumni extends Component {
    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        let d = {
            dept_code: this.props.data.dept_code
        }

        PostData(`/getalumnibydept`, d)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Our Alumni - Our Pride`}
                    />

                    <br />

                    {this.state.isLoaded ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Batch</TableCell>
                                    <TableCell>Current Profession</TableCell>
                                    <TableCell>Contact</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.data.map((el, index) => 
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.name}</TableCell>
                                    <TableCell>{el.batch}</TableCell>
                                    <TableCell>{el.current_profession}</TableCell>
                                    <TableCell>{el.email}<br /> {el.phone}</TableCell>
                                </TableRow>
                            )}
                        </Table>
                    ) : <Typography>Loading . . . </Typography>}
                </Container>
            </div>
        )
    }
}
