import { MDBContainer } from 'mdbreact'
import React from 'react'
import { PostData } from '../../api/service'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from "reactstrap";
import {Grid} from '@material-ui/core'
import VideoPlayer from './VideoPlayer';

export default function TakeATour() {

    const [photos, setPhotos] = React.useState([])
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [activeIndex, setActiveIndex] = React.useState(0)


    React.useEffect(() => {
        PostData(`/getphotosforwebsite`, {
            type: "FOOTER_IMAGES"
        }).then((resp) => {

            setPhotos(resp)
            setIsLoaded(true)

        })
    }, [])

    let animating = null;

    function onExiting() {
        animating = true;
    }

    function onExited() {
        animating = false;
    }

    function next() {
        if (animating) return;
        const nextIndex =
            activeIndex === photos.length - 1
                ? 0
                : activeIndex + 1;
        setActiveIndex(nextIndex)
    }

    function previous() {
        if (animating) return;
        const nextIndex =
            activeIndex === 0
                ? photos.length - 1
                : activeIndex - 1;
        setActiveIndex(nextIndex)
    }

    function goToIndex(newIndex) {
        if (animating) return;
        setActiveIndex(newIndex)
    }




    return (
        <div> <div className="news py-4">
            <MDBContainer>
            <style>
          {`.custom-tag {
                width: 100%;
                max-height: 50vh;
                
              }
              .custom-tag1 {
                display: block;
                width: 100%;
                max-height: 50vh;
                
              }
              .mobile-custom-tag-1{
                display: none;
              }
              @media only screen and (max-width: 600px){
                .custom-tag {
                  
                  width: 100%
                }
                .custom-tag1 {
                  
                  display: none;
                }
                .mobile-custom-tag-1{
                  display: block;
                 
                }
              }
              `}
        </style>

               

                <p
                    style={{
                        color: "white",
                        fontSize: "2em",
                        fontWeight: "600",
                        textAlign: "center",
                        margin: "0 0 0 0",
                    }}
                >
                    Take A Tour Of Dispur College
                </p>


<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12}>
    <div style={{ paddingTop: 10, paddingBottom: 10, textAlign: 'center' }}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >

                        {photos.map((item, index) => 
                                <CarouselItem
                                    //className="custom-tag"
                                    tag="div"
                                    key={index}
                                    onExiting={onExiting}
                                    onExited={onExited}
                                >
                                    <img
                                        alt={"FOOTER_IMAGES"}
                                        src={item}
                                        //className="footer-image-large"
                                        style={{objectFit: "cover", height: '600px', width: '80%'}}
                                    />
                                    
                                </CarouselItem>
                            )}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                        />
                    </Carousel>
                   

                </div>
    </Grid>
    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
    <div style={{ paddingTop: 10, paddingBottom: 10, textAlign: 'center' }}>
    <VideoPlayer src={`https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4`} type="video/mp4" />
    </div>
    </Grid> */}
</Grid>
              

            </MDBContainer>

        </div></div>
    )
}
