import React, { Component } from 'react'

import { MDBContainer} from 'mdbreact';
import { Card, CardHeader } from 'reactstrap';
import { Typography } from '@material-ui/core';
import BreadCumb from '../Department/BreadCumb';



export default class GoverningBody extends Component {


    render() {
        return (
            <div>

                

                <MDBContainer style={{ paddingTop: '20px' }}>

                    <br />

                    <div><Card style={{ boxShadow: 'none' }}>
                        <CardHeader
                            style={{ color: 'white', boxShadow: 'none', backgroundColor: '#B94735', textAlign: 'center' }}>
                            <h3 style={{color: 'inherit'}}>Governing Body</h3>
                        </CardHeader>
                    </Card>

                    <br />

                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        The present Governing Body of the College is Consisted of the following Members:
                    </Typography>

                        <Typography
                            variant="body1"
                            gutterBottom
                        >
                           Dr. Balendra Kr. Das<br />
                            President,<br /><br />

                            Dr. Navajyoti Borah<br />
                            Secretary, Principal<br /><br />

                           

                            Dr. Sunita Agarwalla<br />
                            Vice Principal<br /><br />
                            

                            Dr (Mrs) Padma Sarma,<br />
                            GU Member<br /><br />

                            Dr (Mrs) Chandana Sharma<br />
                            GU Member<br /><br />


                            Dr Ajoy Mitra, IQAC Coordinator<br />
                            Special invitee,<br /><br />

                          


                           Dr. Shashi Mohan Das,<br />
                           Teachers Member<br /><br />

                            Dr. Himangshu Kalita,<br />
                            Teachers Member<br /><br />

                            Mrs. Sangeeta Roy<br />
                            Librarian, Ex Officio Member<br /><br />

                            


                            Sri Kanak Chandra Goswami,<br />
                            Guardian Member<br /><br />

                            Mrs Lalita Devi<br />
                            Guardian Member<br /><br />

                            Sri Droneswar Borah <br />
                            Guardian Member<br /><br />


                            Mrs. Indira Gogoi,<br />
                            Non Teaching Member<br /><br />

                            

                            
                    </Typography>

                        


                    </div>






                </MDBContainer>

            </div>
        )
    }
}