import { Container, Grid } from "@mui/material";
import { Divider } from "antd";
import React, { Component } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { PostData } from "../api/service";
import { Typography } from "@material-ui/core";

const handleDragStart = (e) => e.preventDefault();

export default class HightlightShort extends Component {
  state = {
    loadedData: [],
    isLoaded: false,
  };

  componentDidMount() {
    PostData(`/getphotosforwebsitewithcaption`, {
      type: this.props.type,
    }).then((resp) => {
      this.setState({
        loadedData: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoaded && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {this.state.loadedData.filter((el) => el.type == this.props.type)
                  .length > 0 && (
                  <>
                    
                    <Container>
                      <AliceCarousel
                        responsive={{
                          0: {
                            items: 1,
                          },
                          1024: {
                            items: 1,
                            itemsFit: "contain",
                          },
                        }}
                        autoPlay={true}
                        autoWidth={true}
                        autoHeight={true}
                        autoPlayInterval={2000}
                        dotsDisabled={true}
                        mouseTracking
                        items={this.state.loadedData
                          .filter((el) => el.type == this.props.type)
                          .map((el, index) => {
                            return (
                              <div>
                                <img
                                  src={el.url}
                                  onDragStart={handleDragStart}
                                  role="presentation"
                                  style={{
                                    width: "100%",
                                    height: "400px",
                                    objectFit: "contain",
                                  }}
                                />
                                <br />
                                <br />
                                <Typography
                                  style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    marginTop: 20,
                                    textAlign: "center",
                                  }}
                                >
                                  {el.caption}
                                </Typography>
                              </div>
                            );
                          })}
                      />
                    </Container>
                  </>
                )}
              </Grid>

             

              


              
            </Grid>
          </>
        )}
      </div>
    );
  }
}
