import React, { Component } from "react";
import {
  Typography,
} from "@material-ui/core";
import PrincipalContainer from "./PrincipalContainer";
import GoverningBody from "./GoverningBody";

import { MDBContainer } from 'mdbreact';

class AdministrationDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "principal" ? <PrincipalContainer /> : null}
        {this.props.type == "gb" ? <GoverningBody /> : null}
        {this.props.type == "officestaff" ? <OfficeStaff /> : null}
        {this.props.type == "codeofconduct" ? <CodeOfConduct /> : null}
      </div>
    );
  }
}

export default AdministrationDescription;

export const OfficeStaff = () => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
          Office Staff
        </Typography>


        <table
          className="MsoTableGrid"
          border={0}
          cellSpacing={0}
          cellPadding={0}
          style={{ borderCollapse: "collapse", border: "none" }}
        >
          <tbody>
            <tr>
              <td
                width={100}
                valign="top"
                style={{
                  width: "99.9pt",
                  borderStyle: "none none solid",
                  borderBottomWidth: "1pt",
                  borderBottomColor: "windowtext",
                  padding: "0in 5.4pt"
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: "150%" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.0pt",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        msoBidiFontFamily: ""
                      }}
                      times=""
                      new=""
                    >
                      WROK FORCE

                    </span>
                  </b>
                </p>
              </td>
              <td
                width={240}
                valign="top"
                style={{
                  //width: "139.5pt",
                  borderStyle: "none none solid",
                  borderBottomWidth: "1pt",
                  borderBottomColor: "windowtext",
                  padding: "0in 5.4pt"
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: "150%" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.0pt",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        msoBidiFontFamily: ""
                      }}
                      times=""
                      new=""
                    >
                      NAME

                    </span>
                  </b>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{
                  width: "92.7pt",
                  borderStyle: "none none solid",
                  borderBottomWidth: "1pt",
                  borderBottomColor: "windowtext",
                  padding: "0in 5.4pt"
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: "150%" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.0pt",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        msoBidiFontFamily: ""
                      }}
                      times=""
                      new=""
                    >
                      DESIGNATION

                    </span>
                  </b>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{
                  width: "110.7pt",
                  borderStyle: "none none solid",
                  borderBottomWidth: "1pt",
                  borderBottomColor: "windowtext",
                  padding: "0in 5.4pt"
                }}
              >
                <p className="MsoNormal" style={{ lineHeight: "150%" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.0pt",
                        lineHeight: "150%",
                        fontFamily: "Arial",
                        msoBidiFontFamily: ""
                      }}
                      times=""
                      new=""
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", border: "none", padding: "0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    Office Staff

                  </span>
                </p>
              </td>
              <td
                width={240}
                valign="top"
                style={{ width: "139.5pt", border: "none", padding: "0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    MRS INDIRA BORAGOHAIN GOGOI
                  </span>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", border: "none", padding: "0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    UDA

                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", border: "none", padding: "0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            {/* <tr>
      <td
        width={100}
        valign="top"
        style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
            &nbsp;
          </span>
        </p>
      </td>
      <td
        width={140}
        valign="top"
        style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoFareastFontFamily: ""
            }}
            times=""
            new=""
          >
           MRS FULESWARI DEKA
          </span>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
            
          </span>
        </p>
      </td>
      <td
        width={93}
        valign="top"
        style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
            LDA
            
          </span>
        </p>
      </td>
      <td
        width={111}
        valign="top"
        style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
      >
        <p className="MsoNormal" style={{ lineHeight: "115%" }}>
          <span
            style={{
              fontSize: "10.0pt",
              lineHeight: "115%",
              fontFamily: "Arial",
              msoBidiFontFamily: ""
            }}
            times=""
            new=""
          >
            &nbsp;
          </span>
        </p>
      </td>
    </tr> */}
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={240}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    MR KHARGESWAR SAIKIA
                  </span>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    LDA

                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{ fontFamily: "Arial", fontSize: "13.333333015441895px" }}
                  >
                    MR GIRIN SAIKIA
                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  LDA
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    MRS NIHARIKA SARMA
                  </span>
                  
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    LDA

                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    SRI DHIREN KONWAR

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    Grade IV

                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    SRI RAMESH BAISHYA

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <font face="Arial" size={2}>
                    Grade IV
                  </font>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    SRI BRAJEN SARMA

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span style={{ fontFamily: "Arial", fontSize: "small" }}>
                    Grade IV
                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    MRS ANWARA BEGUM

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span style={{ fontFamily: "Arial", fontSize: "small" }}>
                    Grade IV
                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    SRI HIRYANA GAUTAM
                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span style={{ fontFamily: "Arial", fontSize: "small" }}>
                    Grade IV
                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <font face="Arial" size={2}>
                    MRS REENA TALUKDAR
                  </font>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{ fontFamily: "Arial", fontSize: "13.333333015441895px" }}
                  >
                    Grade IV
                  </span>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{ fontFamily: "Arial", fontSize: "13.333333015441895px" }}
                  >
                    SRI BUBUL SARMA
                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <font face="Arial" size={2}>
                    Grade IV
                  </font>
                </p>
              </td>
              <td
                width={111}
                valign="top"
                style={{ width: "110.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
            </tr>

            {/* <tr> */}
            <td
              width={100}
              valign="top"
              style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
            >
              &nbsp;
            </td>

            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    Library Staff

                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    MRS SANGEETA ROY

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    Librarian

                  </span>
                </p>
              </td>

            </tr>
            <tr>
              <td
                width={100}
                valign="top"
                style={{ width: "99.9pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    &nbsp;
                  </span>
                </p>
              </td>
              <td
                width={140}
                valign="top"
                style={{ width: "139.5pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoFareastFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    SRI AJIT MALAKAR

                  </span>
                </p>
              </td>
              <td
                width={93}
                valign="top"
                style={{ width: "92.7pt", padding: "0in 5.4pt 0in 5.4pt" }}
              >
                <p className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <span
                    style={{
                      fontSize: "10.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      msoBidiFontFamily: ""
                    }}
                    times=""
                    new=""
                  >
                    Library Bearer

                  </span>
                </p>
              </td>
            </tr>






          </tbody>
        </table>
      </MDBContainer>

    </div>
  )
}


export const CodeOfConduct = () => {
  return (
    <div>
      <MDBContainer style={{ paddingTop: '20px' }}>
        <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
          Code of Conduct
        </Typography>


        <iframe src="/pdf/Code_of_Conduct.pdf" width={'100%'} height="1000px"></iframe>

      </MDBContainer>

    </div>
  )
}