import React, { Component } from "react";
import { Container, Typography } from "@material-ui/core";
import TitleStyle from "../TitleStyle";
import { Tabs } from "antd";
import { PostData } from "../../api/service";
import MUIDataTable from "mui-datatables";
import AttachmentIcon from "@material-ui/icons/Attachment";

class QuestionBank extends Component {
  render() {
    return (
      <div>
        <Container>
          <TitleStyle title={`Self-paced Learning Resources`} />

          <br />

          <Tabs>
            <Tabs.TabPane tab="Question Bank" key="QUESTION_BANK">
              <Library
                type="QUESTION_BANK"
                dept_code={this.props.data.dept_code}
                title="Question Bank"
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Video Bank" key="VIDEO_BANK">
              <Library
                type="VIDEO_BANK"
                dept_code={this.props.data.dept_code}
                title="Video Bank"
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="PPT Bank" key="PPT_BANK">
            <Library
                type="PPT_BANK"
                dept_code={this.props.data.dept_code}
                title="PPT Bank"
              />
            </Tabs.TabPane>
          </Tabs>
        </Container>
      </div>
    );
  }
}

export default QuestionBank;

export const Library = ({ type, dept_code, title }) => {
  const [data, setData] = React.useState([]);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    __loadData(0, 10, "");
  }, []);

  function __loadData(page, rowsPerPage, search) {
    setIsLoaded(false);
    setSearchText(search);

    PostData(
      `/getquestionbankbytype?page=${page}&per_page=${rowsPerPage}&search=${search}`,
      {
        type: type,
        dept_code: dept_code,
      }
    ).then((resp) => {
      setData(resp.data);
      setRowsPerPage(rowsPerPage);
      setPage(page);
      setCount(resp.count);
      setIsLoaded(true);
    });
  }

  // function __loadFile() {
  //   GetDataExtra(`/get-all-class-notes`).then((resp) => {
  //     console.log(resp);
  //     setData(resp);
  //     setIsLoaded(true);
  //   });
  // }

  const columns = [
    {
      name: "sub_type",
      label: "TYPE OF QUESTION BANK",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "academic_program",
      label: "ACADEMIC PROGRAMME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_name",
      label: "PAPER NAME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_code",
      label: "PAPER NAME",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "semester",
      label: "SEMESTER/ YEAR",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "resource_generated_by",
      label: "RESOURCE GENERATED BY",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "year",
      label: "YEAR OF QUESTION BANK GENERATION",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "upload_info",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              {Array.isArray(row) &&
                row.length > 0 &&
                row.map((el, index) => (
                  <a target="_blank" href={el.url}>
                    <AttachmentIcon style={{ fontSize: 20 }} /> View
                  </a>
                ))}
            </div>
          );
        },
      },
    },
  ];

  const columnsVideo = [
    {
      name: "sub_type",
      label: "TYPE OF VIDEO RESOURCE",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "academic_program",
      label: "TARGET ACADEMIC PROGRAMME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_name",
      label: "ASSOCIATED PAPER NAME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_code",
      label: "ASSOCIATED PAPER CODE",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "semester",
      label: "TARGET SEMESTER/ YEAR",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "topic",
      label: "TOPIC OF THE VIDEO",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "name_of_external",
      label: "NAME OF EXTERNAL/ INTERNAL EXPERT",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "resource_generated_by",
      label: "RESOURCE GENERATED BY",
      options: {
        filter: true,
        sort: false,
      },
    },

    
    {
      name: "link_video",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div style={{textAlign: "center"}}>
              {Array.isArray(row.thumbnail) &&
                row.thumbnail.length > 0 &&
                <>
                    <img
                        src={row.thumbnail[0].url}
                        style={{width: 100}}
                     />
                </>
              }
              <a href={row.link} target="_blank">View</a>
            </div>
          );
        },
      },
    },
  ];


  const columnsPpt = [
    {
      name: "sub_type",
      label: "TYPE OF PPT RESOURCE",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "academic_program",
      label: "TARGET ACADEMIC PROGRAMME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_name",
      label: "ASSOCIATED PAPER NAME",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "paper_code",
      label: "ASSOCIATED PAPER CODE",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "semester",
      label: "TARGET SEMESTER/ YEAR",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "topic",
      label: "TOPIC OF THE PPT",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "name_of_external",
      label: "NAME OF EXTERNAL/ INTERNAL EXPERT",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "resource_generated_by",
      label: "RESOURCE GENERATED BY",
      options: {
        filter: true,
        sort: false,
      },
    },

    
    {
      name: "upload_info",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              {Array.isArray(row) &&
                row.length > 0 &&
                row.map((el, index) => (
                  <a target="_blank" href={el.url}>
                    <AttachmentIcon style={{ fontSize: 20 }} /> View
                  </a>
                ))}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    responsive: "scroll",
    rowsPerPage: rowsPerPage,
    count: count,
    page: page,
    searchText: searchText,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: !isLoaded
          ? "Loading..... Please wait."
          : "Sorry, there is no matching data to display",
      },
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action == "changePage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "changeRowsPerPage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "search") {
        setSearchText();
        if (tableState.searchText != null && tableState.searchText.length > 3) {
          __loadData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
        }
        if (tableState.searchText == null) {
          __loadData(tableState.page, tableState.rowsPerPage, "");
        }
      }
    },
  };

  return (
    <div className="text-justify">
      {type == "QUESTION_BANK" && (
        <MUIDataTable
          title={title}
          data={[
            ...data.map((el, index) => {
              return {
                ...el,
              };
            }),
          ]}
          columns={columns}
          options={options}
        />
      )}

      {type == "VIDEO_BANK" && (
        <MUIDataTable
          title={title}
          data={[
            ...data.map((el, index) => {
              return {
                ...el,
                link_video: {
                    link: el.link,
                    thumbnail: el.upload_info
                }
              };
            }),
          ]}
          columns={columnsVideo}
          options={options}
        />
      )}


      {type == "PPT_BANK" && (
        <MUIDataTable
          title={title}
          data={[
            ...data.map((el, index) => {
              return {
                ...el,
                link_video: {
                    link: el.link,
                    thumbnail: el.upload_info
                }
              };
            }),
          ]}
          columns={columnsPpt}
          options={options}
        />
      )}
    </div>
  );
};
