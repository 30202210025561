import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./PanduNav.css";
import SubNav3 from "./SubNav3";
import { NavLink, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@material-ui/core";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

export default class PanduNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div>
        
        <div className="m-none">
          
          <Navbar expand="md" className="main-nav-class">
            <div className="nav-logo-div">
              <img className="nav-logo-img" src="/newimages/75.png" />

              <img className="nav-logo-img" src="/newimages/iso-9001.png" />
            </div>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
                //width: "500px",
                //borderRight: "3px solid #251d25",
              }}
            >
              <Typography
                className="nav-bar-div-text-h"
                variant="h1"
                style={{ fontWeight: 700 }}
              >
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name_as}</span>
                ) : (
                  "DISPUR COLLEGE"
                )}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/dispur_college.png"
                    style={{ width: "9em" }}
                    alt="Dispur College1"
                  />
                )}
              </Typography>
            </div>
            <div>
              <a href="/">
                <img
                  className="logo-img"
                  src="/newimages/dc.png"
                  style={{ width: 120 }}
                  alt="logo"
                />
              </a>
            </div>
            <div
              style={{
                padding: "0 10px",
              }}
            >
              <h6 className="mrinh6">
                <HomeOutlined style={{ color: "#426C92" }} />
                &nbsp;&nbsp;Guwahati -781 006 , Assam, India.
              </h6>
              <h6 className="mrinh6">
                <PhoneOutlined style={{ color: "#426C92" }} />
                &nbsp;&nbsp;0361 - 3513202, 0361 - 3515332
              </h6>
              <h6 className="mrinh6">
                <MailOutlined style={{ color: "#426C92" }} />
                &nbsp;&nbsp;dispurcollege1978@gmail.com
              </h6>
            </div>
            <div className="nav-logo-div">
              <img className="nav-logo-img" src="/newimages/AICTE-Logo-250x250-1.webp" style={{width: 70}} />

              <img className="nav-logo-img" src="/newimages/g20.jpeg" />
            </div>
            {/* <div>
              <a target="_blank" href="/aboutus/certifications">
                <img
                  style={{ width: 70 }}
                  src="/images/ISO_9001-2015.svg.png"
                />
              </a>
            </div> */}
            <br />
          </Navbar>
        </div>

        {/* for mobile */}
        <div className="m-display">
          <Navbar expand="md" className="main-nav-class m-display">
            <a href="/">
              <img className="logo-img" src={"/images/ll.png"} alt="logo" />
            </a>
            <div>
              <a target="_blank" href="/aboutus/certifications">
                <img
                  style={{ width: 50 }}
                  src="/images/ISO_9001-2015.svg.png"
                />
              </a>
            </div>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
              }}
            >
              <Typography className="nav-bar-div-text-h" variant="h1">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name_as}</span>
                ) : (
                  "DISPUR COLLEGE"
                )}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/dispur_college.png"
                    style={{ width: "6em" }}
                    alt="Dispur College1"
                  />
                )}
              </Typography>
            </div>
          </Navbar>
        </div>

        <SubNav3
          sticky={this.state.sticky}
          width={`200px`}
          color={`#ff6633`}
          backgroundOfNav={`white`}
          backgroundColor={`#426C92`}
          sticky={this.state.sticky}
          textColor={`white`}
          history={this.props.history}
        />
      </div>
    );
  }
}
