import { Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { PostData } from "../api/service";
import FleshNews from './Fleshnew';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const items = [
  // {
  //   id: 1,
  //   src: "/gallery/header/dc-1.jpg",
  //   altText: "Slide 1",
  // },
  // {
  //   id: 2,
  //   src: "/gallery/header/dc-2.jpg",
  //   altText: "Slide 2",
  // },
  {
    id: 0,
    src: "/gallery/header/dc-24.jpg",
    altText: "Slide 4",
  },
  {
    id: 0,
    src: "/gallery/header/dc-23.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-19.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-20.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-21.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-22.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-18.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-17.jpg",
    altText: "Slide 3",
  },
  {
    id: 0,
    src: "/gallery/header/dc-13.jpg",
    altText: "Slide 3",
  },
  {
    id: 1,
    src: "/gallery/header/dc-14.jpg",
    altText: "Slide 3",
  },
  {
    id: 2,
    src: "/gallery/header/dc-15.jpg",
    altText: "Slide 3",
  },
  {
    id: 13,
    src: "/gallery/header/dc-16.jpg",
    altText: "Slide 3",
  },
  {
    id: 3,
    src: "/gallery/header/dc-12.jpg",
    altText: "Slide 3",
  },
  // {
  //   id: 3,
  //   src: "/gallery/header/dc-3.jpg",
  //   altText: "Slide 3",
  // },
  {
    id: 4,
    src: "/gallery/header/dc-4.jpg",
    altText: "Slide 4",
  },
  {
    id: 5,
    src: "/gallery/header/dc-5.jpg",
    altText: "Slide 5",
  },
  {
    id: 6,
    src: "/gallery/header/dc-6.jpg",
    altText: "Slide 6",
  },
  {
    id: 7,
    src: "/gallery/header/dc-7.jpg",
    altText: "Slide 7",
  },
  {
    id: 8,
    src: "/gallery/header/dc-8.jpg",
    altText: "Slide 8",
  },
  {
    id: 9,
    src: "/gallery/header/dc-9.jpg",
    altText: "Slide 9",
  },


];



const handleDragStart = (e) => e.preventDefault();


const items1 = [
  <img src="/images/dispur-iso.jpeg" onDragStart={handleDragStart} role="presentation" style={{width: "100%", objectFit: "cover"}} />,
  <img src="/images/dc-iso-1.png" onDragStart={handleDragStart} role="presentation" style={{width: "100%", objectFit: "cover"}} />,
  <img src="/newimages/dc-ac.jpeg" onDragStart={handleDragStart} role="presentation" style={{width: "100%", objectFit: "cover"}} />,
  <img src="https://dispurcollege.ac.in/images/dispur-nipam.png" onDragStart={handleDragStart} role="presentation" style={{width: "100%", objectFit: "cover"}} />,
];

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, allPhotos: [], isLoaded: false };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.allPhotos.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.allPhotos.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  componentDidMount(){
    PostData(`/getphotosforwebsite`,{
      type: "BANNER_IMAGES"
    }).then((resp) => {
      
      this.setState({
        allPhotos: resp,
        isLoaded: true,
        activeIndex: 0
      })
      
    })
  }

  render() {
    const { activeIndex } = this.state;

    // const slides = items.map((item) => {
    //   return (
    //     <CarouselItem
    //       className="custom-tag"
    //       tag="div"
    //       key={item.id}
    //       onExiting={this.onExiting}
    //       onExited={this.onExited}
    //     >
    //       <img
    //         alt={item.altText}
    //         src={item.src}

    //         className="carousel-main-image"
    //       />
    //       {/* <CarouselCaption
    //         className="text-danger"
    //         captionText={item.head}
    //         captionHeader={item.head}
    //       /> */}
    //     </CarouselItem>
    //   );
    // });

    const slides = this.state.allPhotos.map((item, index) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={index}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img
            alt={"BANNER_IMAGES"}
            src={item}

            className="carousel-main-image"
          />
          {/* <CarouselCaption
            className="text-danger"
            captionText={item.head}
            captionHeader={item.head}
          /> */}
        </CarouselItem>
      );
    });

    const slidesSingle = [<CarouselItem
    className="custom-tag"
    tag="div"
    key={100}
    onExiting={this.onExiting}
    onExited={this.onExited}
  >
    <img
      alt={"BANNER_IMAGES"}
      src={"/gallery/header/dc-24.jpg"}

      className="carousel-main-image"
    />
    {/* <CarouselCaption
      className="text-danger"
      captionText={item.head}
      captionHeader={item.head}
    /> */}
  </CarouselItem>]

    return (
      <div style={{ width: "100%" }}>


        <style>
          {`.custom-tag {
                width: 100%;
                max-height: 50vh;
                background: #2D3192;
              }
              .custom-tag1 {
                display: block;
                width: 100%;
                max-height: 50vh;
                background: #2D3192;
              }
              .mobile-custom-tag-1{
                display: none;
              }
              @media only screen and (max-width: 600px){
                .custom-tag {
                  
                  width: 100%
                }
                .custom-tag1 {
                  
                  display: none;
                }
                .mobile-custom-tag-1{
                  display: block;
                  background: #2D3192;
                }
              }
              `}
        </style>
        <Grid container>
          <Grid item xs={12} sm={12} md={2} lg={2} className="custom-tag1">
            <div style={{ padding: 5, minHeight: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>

              {/* <img
                src={"/images/dispur-iso.jpeg"}
                alt="dc-principal"
                style={{ width: '100%', height: '50vh', marginBottom: 5 }}
              /> */}
              <AliceCarousel autoPlay={true} autoPlayInterval={2000} dotsDisabled={true}  mouseTracking items={items1} />

            </div>

          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
            >
              {/* <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          /> */}
              {this.state.isLoaded ? slides : slidesSingle}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>

          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="custom-tag1" >
            <div style={{ padding: 5, minHeight: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>

              <img
                src={"/images/dc-sdg-certificate.jpeg"}
                alt="dc-sdg"
                style={{ width: '100%', marginBottom: 5, objectFit: "cover" }}
              />

            </div>
            {/* <div style={{ padding: 5 }}>

              <img
                src={"/images/dc-principal-small.png"}
                alt="dc-principal"
                style={{ width: '100%', marginBottom: 5 }}
              />
              <Typography style={{ marginTop: 10, color: 'white', fontWeight: 700 }}>
                <i>Dear Students,
                  <br />
                  I would like to take this opportunity to heartily invite each one of you, who desires to gain knowledge......<Link to="/administrations/principal">See More</Link></i>
              </Typography>
            </div> */}

          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6} sm={6} lg={6} md={6} className="mobile-custom-tag-1">
            <div style={{ padding: 5, minHeight: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>

              <img
                src={"/images/dispur-iso.jpeg"}
                alt="dc-principal"
                style={{ width: '100%', height: '350px' }}
              />

            </div>
          </Grid>
          <Grid item xs={6} sm={6} lg={6} md={6} className="mobile-custom-tag-1">
          <div style={{ padding: 5, minHeight: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>

<img
  src={"/images/dc-sdg-certificate.jpeg"}
  alt="dc-sdg"
  style={{ width: '100%', height: '350px' }}
/>

</div>
            {/* <div style={{ padding: 5 }}>

              <img
                src={"/images/dc-principal-small.png"}
                alt="dc-principal"
                style={{ width: '100%', marginBottom: 5 }}
              />
              <Typography style={{ marginTop: 10, color: 'white', fontWeight: 700 }}>
                <i>Dear Students,
                  <br />
                  I would like to take this opportunity to heartily invite each one of you, who desires to gain knowledge......<Link to="/administrations/principal">See More</Link></i>
              </Typography>
            </div> */}
          </Grid>
        </Grid>





        <div stle={{ paddingTop: 20 }}><FleshNews /></div>
      </div>
    );
  }
}

export default Example;
