import React, { useRef, useEffect, useState } from 'react';

const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [videoRef]);

  useEffect(() => {
    if (isVisible) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isVisible]);

  return (
    <video ref={videoRef} poster="/pdf/dc-fallback-video.png" autoPlay loop muted style={{width: "100%", height: 600, objectFit: "cover"}}>
      <source src={`/pdf/dc-video-11.mp4`} type="video/mp4"/>
      </video>
  );
};

export default VideoComponent;
