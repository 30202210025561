import React from 'react'
import {Container} from '@material-ui/core'
import {HomeOutlined} from '@ant-design/icons'

export default function Breadcrubms({ history }) {

    let pathname = history.location.pathname;

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        let d = pathname.split('/');
        setData(d)
    }, [pathname])




    if(pathname != "/"){
        return (
            <div style={{height: 120, backgroundColor: '#2D3192', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Container>
                    <div style={{display: 'flex', alignItems: 'center', color: 'white'}}>
                    {Array.isArray(data) && data.map((el,index) => <div style={{marginRight: 20}} key={index}>
                        <div style={{display: 'flex', alignItems: 'center', fontWeight: 700}}>{el == "" && <HomeOutlined onClick={() => history.push("/")} style={{color: 'white', fontSize: 16}} />}
                        {el != "" && `${el.toUpperCase()}`} {index+1 < data.length && <span style={{marginLeft: 20}}>/</span>}</div>
                        
                    </div>)}
                    </div>
                </Container>
            </div>
          )
    }else{
        return null;
    }

  
}
