import React, { Component } from "react";
import { Divider, Grid, Paper, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import CommiteeCode from "../Committee/CommiteeCode";
import IqacMember from "./IqacMemeber";
import { PostData } from "../../api/service";
import NotificationDescription from "../Notifications/NotificationDescription";
import { Table } from '@material-ui/core'
import { Button, Card, Spin } from 'antd'

import QRCode from "react-qr-code";

const initialState = [];
class IqacDescription extends Component {
  render() {
    return (
      <div>
        {this.props.type == "about" ? <About type="streategies" code="IQAC_STRETEGIES" title="About" /> : null}
        {this.props.type == "streategies" ? <NotificationDescription type="streategies" code="IQAC_STRETEGIES" title="IQAC Stretegies" /> : null}
        {this.props.type == "functions" ? <NotificationDescription type="streategies" code="IQAC_FUNCTIONS" title="IQAC Functions" /> : null}

        {/* // getcommitteebycode */}
        {this.props.type == "teams" ? <CommiteeCode committee_code="IQAC" /> : null}
        {this.props.type == "previosconvenor" ? <Building2 committee_code="IQAC" /> : null}
        {this.props.type == "assistantcoordinator" ? <Building3 committee_code="IQAC" /> : null}
        {this.props.type == "feedbacks" ? <FeedbackLanding /> : null}

        {this.props.type == "ssr" ? <NotificationDescription type="streategies" code="IQAC_SSR" title="IQAC Self Study Reports" /> : null}
        {this.props.type == "sops" ? <NotificationDescription type="streategies" code="IQAC_SOP" title="SOPs & Policies" /> : null}
        {this.props.type == "collaborations" ? <NotificationDescription type="streategies" code="IQAC_COLLBORATIONS" title="MoUs & Collaborations" /> : null}
        {this.props.type == "ssr" ? <NotificationDescription type="streategies" code="IQAC_SSR" title="IQAC Self Study Reports" /> : null}
        {this.props.type == "iic" ? <NotificationDescription type="iic" code="IQAC_IIC" title="IIC" /> : null}
        {this.props.type == "notices" ? <NotificationDescription type="iic" code="IQAC_NOTICE" title="IQAC Notifications" /> : null}
        {this.props.type == "aqar" ? <NotificationDescription type="streategies" code="IQAC_AQAR" title="IQAC AQAR" /> : null}
        {this.props.type == "atr" ? <NotificationDescription type="streategies" code="IQAC_ATR" title="IQAC Action taken Report" /> : null}
        {this.props.type == "aishe" ? <NotificationDescription type="streategies" code="IQAC_AISHE" title="AISHE Certificates" /> : null}
        {this.props.type == "minutes" ? <NotificationDescription type="streategies" code="IQAC_MIUNTES" title="IQAC Minutes" /> : null}
        {this.props.type == "feedback" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_FORM" title="IQAC Feedback Form" /> : null}
        {this.props.type == "feedback-analysis" ? <NotificationDescription type="streategies" code="IQAC_FEEDBACK_ANALYSIS" title="IQAC Feedback Analysis" /> : null}
        {this.props.type == "sss" ? <NotificationDescription type="streategies" code="IQAC_SSS" title="IQAC Student Satisfactory Survey" /> : null}
        {this.props.type == "event-news" ? <NotificationDescription type="streategies" code="IQAC_EVENT" title="IQAC News/Event" /> : null}
        {this.props.type == "declaration" ? <NotificationDescription type="streategies" code="IQAC_DECLARATION" title="IQAC Declaration" /> : null}
        {this.props.type == "institutional-perspective-plan" ? <NotificationDescription type="streategies" code="IQAC_IPP" title="Institutional Perspective Plan" /> : null}


        {this.props.type == "best-practices" ? <NotificationDescription type="streategies" code="IQAC_BEST_PRACTICE" title="Best Practices" /> : null}

        {this.props.type == "institutional-distinctiveness" ? <NotificationDescription type="streategies" code="IQAC_INSTITUTIONAL_DISTINCTIVENESS" title="Institutional Distinctiveness" /> : null}


        {this.props.type == "iqac-co" ? <NotificationDescription type="streategies" code="IQAC_CO" title="Course Outcome & Program Outcome" /> : null}
        {this.props.type == "iqac-seminars" ? <NotificationDescription type="streategies" code="IQAC_SEMINARS" title="Seminars/Workshop/Webinars/Conferences" /> : null}
        {this.props.type == "iqac-organogram" ? <NotificationDescription type="streategies" code="IQAC_ORGANOGRAM" title="Organogram" /> : null}
        {this.props.type == "research-papers" ? <NotificationDescription type="streategies" code="IQAC_RESEARCH_PAPERS" title="Research Papers/Books/Edited Books/Book Chapters" /> : null}

      </div>
    );
  }
}

export default IqacDescription;

export const Building1 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        IQAC Members
      </Typography>
      <IqacMember data={initialState} />
    </div>
  );
};





export const M_LIST = [
  {
    name: "Dr Reeta Sharma",
    from: "2003",
    to: "2004"
  },
  {
    name: "Dr Nandini Barooah Sharma",
    from: "2004",
    to: "2005"
  },
  {
    name: "Mrs Jabin Akbar",
    from: "2005",
    to: "2011"
  },
  {
    name: "Dr Rani Mudiar Deka",
    from: "2011",
    to: "2015"
  },
  {
    name: "Dr Joy Jyoti Goswami",
    from: "2015",
    to: "2017"
  },
  {
    name: "Dr. Ajoy Mitra",
    from: "2017",
    to: "2024"
  },
  {
    name: "Dr. Sunita Agarwalla",
    from: "2024",
    to: "Present"
  }
]

export const Building2 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        IQAC Convenor / Coordinators      </Typography>

      <Paper style={{ padding: 10 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {M_LIST.map((el, index) => <TableRow key={index}>
              <TableCell>{el.name}</TableCell>
              <TableCell>{el.from}</TableCell>
              <TableCell>{el.to}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </Paper>

    </div>
  );
};



export const M_LIST_1 = [
  {
    name: "DR. AJOY MITRA",
    from: "2011",
    to: "2017"
  },
  {
    name: "MR PANKAJ KUMAR KATHAR	",
    from: "2017",
    to: "2018"
  },
  {
    name: "DR. PARAG DUTTA",
    from: "2018",
    to: "2021"
  },
  {
    name: "DR. SIKHAJYOTI DEKA",
    from: "2022",
    to: "Till Now"
  },
  {
    name: "DR. SMRITI DEORI",
    from: "2023",
    to: "Till Now"
  }
]

export const Building3 = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        IQAC Assistant Coordinator     </Typography>

      <Paper style={{ padding: 10 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {M_LIST_1.map((el, index) => <TableRow key={index}>
              <TableCell>{el.name}</TableCell>
              <TableCell>{el.from}</TableCell>
              <TableCell>{el.to}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </Paper>
      <br />



    </div>
  );
};




export const FeedbackLanding = () => {
  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  function _loadData() {
    PostData(`/getlinksbycode`, { type: 'FEEDBACK_LINK' })
      .then((resp) => {
        setData(resp)
        setIsLoaded(true)
      }
      ).catch((err) => {
        console.log("🚀 ~ file: FooterPage.js ~ line 20 ~ _loadData ~ err", err)

      })
  }

  React.useEffect(() => {
    _loadData()
  }, [])


  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        Feedbacks     </Typography>

      {!isLoaded && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}><Spin /></div>}


      <Grid container spacing={2}>

        {isLoaded && Array.isArray(data) && data.map((el, index) => <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
          <Card title={el.linkText} actions={[<Button target="_blank" href={el.linkPath} type="primary">Go to Link</Button>]}>
            <div style={{ textAlign: "center" }}>
              <Typography variant="body2" gutterBottom>Scan Here to visit the link</Typography>
              <br />
              <QRCode value={el.linkPath} />
            </div>


          </Card>
        </Grid>)}

      </Grid>




    </div>
  );
};


export const About = (props) => {
  return (
    <div className="mb-4">
      <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
        About Internal Quality Assurance Cell (IQAC)
      </Typography>

      {/* <img
        src={`/images/iqac-new-1.jpeg`}
        style={{ width: `100%` }}
      /> */}

      <p style={{ fontSize: 16 }}>
        Internal Quality Assurance Cell (IQAC)IQAC Convenor / Coordinators:IQAC Assistant Coordinator:The college formed the IQAC in 2003, before the first evaluation of the NAAC in 2004.The college was accredited by the NAAC with B grade in the year 2004. The primary aim of IQAC is-
        <ul>
          <li>
            To develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the institution.
          </li>
          <li>
            To promote measures for institutional functioning towards quality enhancement through internalization of quality culture and institutionalization of best practices.
          </li>
          <li>
            With a vision of attaining and ensuring quality enhancement in the field of higher education, IQAC regularly submitting Annual Quality Assurance Report (AQAR) to NAAC from 2004-05 onwards. In 2015 the college was Re-Accredited (Cycle II) with Grade B.
          </li>
        </ul>
      </p>

      <br />

      <p style={{ textAlign: 'right' }}>
        <div style={{ textAlign: 'right' }}>


          <div style={{ textAlign: 'right', fontWeight: 700 }}>
            <p>Dr. Sunita Agarwalla</p>
            <p>IQAC Coordinator & Vice Principal & Academic Head</p>
          </div>


        </div>
      </p>

    </div>
  );
};

