import React, { Component } from "react";
import { Divider, Grid, Typography, Container, Paper } from "@material-ui/core";
import { PostData, GetData } from "../../api/service";

class PublicationDescription extends Component {
  render() {
    return (
      <div>
        {this.props.type === "college" ? (
          <SiteMap
            title={`College Publications`}
            fileType={`COLLEGE_PUBLICATIONS`}
          />
        ) : null}
        {this.props.type === "department" ? <WallmagazineContainer /> : null}
        {/* {this.props.type === 'teacher' ? (<SiteMap title={`Teacher's Publication`} fileType={`COLLEGE_TEACHER_PUBLICATIONS`} />) : null}
                {this.props.type === 'student' ? (<SiteMap title={`Student's Publication`} fileType={`COLLEGE_STUDENT_PUBLICATIONS`} />) : null} */}
      </div>
    );
  }
}

export default PublicationDescription;

export function SiteMap({ title, fileType }) {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  function fetchAcievements(type) {
    PostData(`/getnoticesbytype`, {
      type,
    })
      .then((resp) => {
        setData(resp);
        setIsLoaded(true);
      })
      .catch((err) => {
        alert(`Error`);
      });
  }

  React.useEffect(() => {
    fetchAcievements(fileType);
  }, []);

  function showImages(upload_info) {
    let i = [];
    return (
      <Grid container spacing={2}>
        {upload_info.map((el, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
            {el.fileType === "image" && (
              <img
                alt="image"
                src={el.url}
                style={{ width: "100%", height: "450px", objectFit: "contain" }}
              />
            )}

            {el.fileType === "file" && (
              <a href={el.url} target="_blank">
                Download File
              </a>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <div>
      <br />
      <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
        {title}
      </Typography>

      <br />

      {isLoaded ? (
        <Grid container spacing={2}>
          {Array.isArray(data) &&
            data.map((el, index) => (
              <Grid item key={index} xs={12} sm={12} lg={6} md={6} >
              <Paper style={{padding: 15, minHeight: 650}}>
                {Array.isArray(el.upload_info)
                  ? showImages(el.upload_info)
                  : null}
                  <br />
                <Typography variant="body2" style={{fontSize: 15, fontWeight: 700, minHeight: 60}}>{el.title}</Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: el.description }}
                ></Typography>

</Paper>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Typography>Loading . . . </Typography>
      )}
    </div>
  );
}

export function WallmagazineContainer() {
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }, []);
  return (
    <div>
      <Container style={{ minHeight: "600px" }} className="mb-5">
        <br />
        <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
          Departmental Wall-Magazines
        </Typography>
        <br></br>
        {isLoaded && (
          <div>
            <ul>
              {Array.isArray(data) &&
                data.map((el, index) => (
                  <li key={index}>
                    <a
                      style={{ fontSize: 14 }}
                      targte="_blank"
                      href={`/department/${el.dept_code}/wall-magazine`}
                    >
                      Wall Magazine of {el.dept_name}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </Container>
    </div>
  );
}
