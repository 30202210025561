import React from 'react'
import { Typography, makeStyles, Backdrop, CircularProgress } from '@material-ui/core'



const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        zIndex: 1101,
        backgroundColor: 'white'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        
        color: '#fff'
    },
}))

export default function LazyLoader({ }) {
    let classes = useStyles();
    return (
        <React.Fragment>
           <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
        </React.Fragment>
    )
}