import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import NavigateNext from "@material-ui/icons/NavigateNext";
import IqacDescription from "./IqacDescription";
import BreadCumb from "../Department/BreadCumb";

const list = [
  {
    title: "About",
    link: "/iqac/about",
  },
  // {
  //   title: "Functions",
  //   link: "/iqac/functions",
  // },
  {
    title: "IQAC Committee",
    link: "/iqac/teams",
  },
  {
    title: "SSR",
    link: "/iqac/ssr",
  },
  {
    title: "AQAR",
    link: "/iqac/aqar",
  },
  {
    title: "Action Taken Report",
    link: "/iqac/atr",
  },
  {
    title: "Meeting Minutes",
    link: "/iqac/minutes",
  },
  {
    title: "Feedbacks",
    link: "/iqac/feedbacks",
  },
  {
    title: "IQAC Convenor / Coordinators",
    link: "/iqac/previosconvenor",
  },
  {
    title: "IQAC Assistant Coordinator",
    link: "/iqac/assistantcoordinator",
  },
  {
    title: "Feedbacks",
    link: "/iqac/feedbacks",
  },
  {
    title: "SOPs & Policies",
    link: "/iqac/sops",
  },
  {
    title: "MoUs & Collaborations",
    link: "/iqac/collaborations",
  },
   {
    title: "AISHE Certificates",
    link: "/iqac/aishe",
  },
  {
    title: "Notifications",
    link: "/iqac/notices",
  },
  {
    title: "Declaration",
    link: "/iqac/declaration",
  },
  {
    title: "Institutional Perspective Plan",
    link: "/iqac/institutional-perspective-plan",
  },

  {
    title: "Best Practices",
    link: "/iqac/best-practices",
  },

  {
    title: "Institutional Distinctiveness",
    link: "/iqac/institutional-distinctiveness",
  },
  {
    title: "Course Outcome & Program Outcome",
    link: "/iqac/iqac-co",
  },
  {
    title: "Seminars/Workshop/Webinars/Conferences",
    link: "/iqac/iqac-seminars",
  },
  {
    title: "Organogram",
    link: "/iqac/iqac-organogram",
  },
  {
    title: "Research Papers/Books/Edited Books/Book Chapters",
    link: "/iqac/research-papers",
  },
  
  // {
  //   title: "IIC",
  //   link: "/iqac/iic",
  // },
  // {
  //   title: "Feedback Forms",
  //   link: "/iqac/feedback",
  // },
  // {
  //   title: "Feedback Analysis",
  //   link: "/iqac/feedback-analysis",
  // },
  // {
  //   title: "Student Satisfactory Survey",
  //   link: "/iqac/sss",
  // },
  // {
  //   title: "Event / News",
  //   link: "/iqac/event-news",
  // },
  // {
  //   title: "Academic Calender",
  //   link: "/iqac/event-news",
  // },
];


class IqacComponent extends Component {
  state = {
    link: "",
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.5em",
                            fontWeight: "300",
                            padding: "0",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <IqacDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default IqacComponent;
