import React, { Component } from "react";
import { PostData } from "../../api/service";
import AliceCarousel from "react-alice-carousel";
import { Typography } from "@material-ui/core";
import "react-alice-carousel/lib/alice-carousel.css";
const handleDragStart = (e) => e.preventDefault();

export default class FacultyGallery extends Component {
  state = {
    data: [],
    isLoaded: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    PostData(`/getgallery`, {
      dept: this.props.employee_id,
      type: "FACULTY",
    })
      .then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true,
        });
      })
      .catch((err) => {
        console.log(`Error`, err);
      });
  };
  render() {
    return (
      <div>
        {this.state.data.length > 0 && (
          <AliceCarousel
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 1,
                itemsFit: "contain",
              },
            }}
            autoPlay={true}
            autoWidth={true}
            autoHeight={true}
            autoPlayInterval={2000}
            dotsDisabled={true}
            mouseTracking
            items={this.state.data.map((el, index) => {
              return (
                <div>
                  <AliceCarousel
                    key={index}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      1024: {
                        items: 1,
                        itemsFit: "contain",
                      },
                    }}
                    autoPlay={true}
                    autoWidth={true}
                    autoHeight={true}
                    autoPlayInterval={2000}
                    dotsDisabled={true}
                    mouseTracking
                    items={el.upload_info.map((el1, index1) => {
                      return (
                        <div key={index1}>
                          <img
                            src={el1.url}
                            onDragStart={handleDragStart}
                            role="presentation"
                            style={{
                              width: "100%",
                              height: "400px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      );
                    })}
                  />

                 
                  <br />
                  <br />
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    {el.caption}
                  </Typography>
                </div>
              );
            })}
          />
        )}
      </div>
    );
  }
}
