import React from "react";
import { Grid, Typography } from "@material-ui/core";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { PostData } from "../../api/service";

const handleDragStart = (e) => e.preventDefault();
export default function Testimonials() {
 

  const [readMore, setReadMore] = React.useState(false);

  const [data, setData] = React.useState([]);
  console.log("🚀 ~ Testimonials ~ data:", data);
  const [isLoaded, setIsLoaded] = React.useState(false);

  function __loadData() {
    let d = {
      dept_code: "DISPUR",
      type: "COLLEGE_TESTIMONIALS"
    };

    PostData(`/getnoticesbytypedept`, d).then((resp) => {
      setData([...resp]);
      setIsLoaded(true);
    });
  }

  React.useEffect(() => {
    __loadData();
  }, []);

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  return (
    <div className="py-4">
     
   <Typography variant="h6" style={{ fontWeight: 700, textAlign: "center" }} gutterBottom>
            Appreciations & Testimonials
            </Typography>

      <AliceCarousel
        syncStateOnPropsUpdate={false}
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 3,
            itemsFit: "contain",
          },
        }}
        activeIndex={2}
        autoPlay={true}
        autoPlayInterval={3000}
        mouseTracking
        items={data.map((el, index) => {
          let url = "/images/office/default.png";

          if (Array.isArray(el.upload_info) && el.upload_info.length > 0) {
            if (el.upload_info[0].fileType == "image") {
              url = el.upload_info[0].url;
            }
          }

          return (
            <div
              key={index}
              onDragStart={handleDragStart}
              role="presentation"
              style={{
                backgroundColor: "white",
                padding: 20,
                borderRadius: 10,
                margin: 10,
              }}
            >
              <img
                src={url}
                style={{
                  width: "100%",
                  height: 400,
                  objectFit: "contain",
                }}
              />

              <div
                style={{
                 // borderTop: "solid thin grey",
                  textAlign: "justify",

                  fontSize: 16,
                  marginTop: 10,
                  minHeight: 200,
                  paddingTop: 10,
                }}
              >
                <div style={{ fontWeight: 700, fontSize: 18 }}>{el.name}</div>

                <br />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={12} md={12}>
                   
                    
                  <Typography
                                                variant="body2"
                                                dangerouslySetInnerHTML={{ __html: el.description }}
                                            >
                                            </Typography>
                  </Grid>
                 
                </Grid>
              </div>
            </div>
          );
        })}
      />
    </div>
  );
}
