import { Container } from "@mui/material";
import { Divider } from "antd";
import React, { Component } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { PostData } from "../api/service";

const handleDragStart = (e) => e.preventDefault();

export default class Hightlights extends Component {
  state = {
    loadedData: [],
    isLoaded: false,
  };

  componentDidMount() {
    PostData(`/getphotosforwebsite`, {
      type: "HIGHTLIGHTS",
    }).then((resp) => {
      this.setState({
        loadedData: resp,
        isLoaded: true,
      });
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoaded && (
          <div className="py-4">
            <Divider
              orientation="left"
              style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
            >
              ADMISSION HIGHLIGHTS
            </Divider>
            <AliceCarousel
              responsive={{
                0: {
                  items: 1,
                },
                1024: {
                  items: 1,
                  itemsFit: "contain",
                },
              }}
              autoPlay={true}
              autoWidth={true}
              autoHeight={true}
              autoPlayInterval={2000}
              dotsDisabled={true}
              mouseTracking
              items={this.state.loadedData.map((el, index) => {
                return (
                  <img
                    src={el}
                    onDragStart={handleDragStart}
                    role="presentation"
                    style={{
                      width: "90%",
                      height: "720px",
                      objectFit: "contain",
                      backgroundColor:"white"
                    }}
                  />
                );
              })}
            />
          </div>
        )}
      </div>
    );
  }
}
