import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class ResearchPage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Research`}
                    />

                    <br />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: this.props.data.online_classes }}
                    style={{textAlign:'justify'}}>

                    </Typography>
                </Container>
            </div>
        );
    }
}

export default ResearchPage;