import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';
import { PostData } from '../../api/service';
import Gallery from 'react-grid-gallery';



class IicGalleryPage extends Component {

    state = {
        data: [],
        isLoaded: false
    }


    componentDidMount() {
        this.loadData()
    }

    loadData = () => {

        let d = {
            dept_code: 'DISPUR',
            type: this.props.code,
            year: ""
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })


    }

    render() {

        const onInit = () => {
            console.log('lightGallery has been initialized');
        };



        return (
            <div>
                <Container>
                    

                    <br />
                    <br />

                    {!this.state.isLoaded && `Loading Gallery...Please wait`}


                    {this.state.isLoaded && Array.isArray(this.state.data) && this.state.data.length == 0 && `No albums to view`}
                    {this.state.isLoaded && Array.isArray(this.state.data) && this.state.data.length > 0 && <div className="row">
                        {this.state.data.map((el, index) => {
                            let img = [];
                            if (Array.isArray(el.upload_info)) {
                                img = el.upload_info.map((el1) => ({
                                    src: el1.url,
                                    thumbnail: el1.url
                                }))
                            }
                          
                            return (
                                <div style={{ marginBottom: '40px' }} className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                                    <h3 style={{ textTransform: 'uppercase' }}>{el.title}</h3>
                                    <div style={{ textTransform: 'uppercase' }} dangerouslySetInnerHTML={{__html: el.description}}></div>
                                    <Gallery images={img} />
                                </div>
                            )
                        }

                        )}
                    </div>}



                </Container>
            </div>
        );
    }
}

export default IicGalleryPage;