import React, { Component } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Card } from "react-bootstrap";
import FacilityDescription from "./FacilityDescription";
import { GetData } from "../../api/service";

const list = [
  {
    title: "Library",
    link: "/facility/library",
  },
  // {
  //   title: "Boys Hostel",
  //   link: "/facility/boyshostel",
  // },
  // {
  //   title: "Girls Hostel",
  //   link: "/facility/girlshostel",
  // },
  // {
  //   title: "CIF",
  //   link: "/facility/cif",
  // },
  {
    title: "Canteen",
    link: "/facility/canteen",
  },
  {
    title: "Auditorium",
    link: "/facility/auditorium",
  },
  {
    title: "Conference Hall",
    link: "/facility/conferencehall",
  },
  {
    title: "Gymnasium",
    link: "/facility/gymnasium",
  },
  // {
  //   title: "ICGC",
  //   link: "/facility/icgc",
  // },
];

class PanduFacility extends Component {
  state = {
    link: "",
    list: [],
    isListLoaded: false
  };
  componentDidMount() {
    let link = this.props.match.params.link;
    this.setState({
      link: link,
    });

    this.loadData();
  }

  createBlogLink = (title) => {
    // Convert the title to lowercase
    let lowerCaseTitle = title.toLowerCase();
    
    // Replace spaces and special characters with hyphens
    let urlFriendlyTitle = lowerCaseTitle.replace(/[^a-z0-9]+/g, '-');
    
    // Remove leading or trailing hyphens
    urlFriendlyTitle = urlFriendlyTitle.replace(/^-+|-+$/g, '');
    
    return urlFriendlyTitle;
}


  loadData = () => {
    GetData(`/getcollegefacilitylist`)
    .then((resp) => {
      this.setState({
        list: resp.map((el) => ({
          ...el,
          link: `${this.createBlogLink(el.title)}-${el.id}`
        })),
        isListLoaded: true
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.link != this.props.match.params.link) {
      let link = this.props.match.params.link;
      this.setState({
        link: link,
      });
    }
  }

  render() {
    return (
      <div>
        <Container className="mb-4" style={{ minHeight: "500px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{ paddingTop: "5em" }}
            >
              <Card
                style={{
                  boxShadow: "none",
                  position: "stickey !important",
                  top: "0 !important",
                }}
              >
                <div
                  style={{
                    boxShadow: "none",
                    position: "stickey",
                    top: "0",
                  }}
                >
                  <List component="nav">
                    {this.state.list.map((el, index) => (
                      <ListItem
                        button
                        style={{
                          borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                        }}
                        key={index}
                        onClick={() => this.props.history.push(el.link)}
                      >
                        <div
                          style={{
                            fontSize: "1.5em",
                            fontWeight: "300",
                            padding: "0",
                          }}
                        >
                          {el.title}
                        </div>
                      </ListItem>
                    ))}
                    {/* <a target="_blank" href="https://ncc.dispurcollege.ac.in/">
                      <ListItem
                        button
                        style={{
                          borderBottom: "1px solid #0002",
                          marginBottom: "2px",
                        }}
                      >
                        NCC
                      </ListItem>
                    </a> */}
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={9} lg={9}>
              <FacilityDescription type={this.state.link} />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default PanduFacility;
