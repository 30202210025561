import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import TitleStyle from '../TitleStyle';
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Grid, CardContent, CardActions, Button } from '@material-ui/core';
import { PostData } from '../../api/service';
import Icon from '@material-ui/core/Icon'



export default class Syllabus extends Component {
  state = {
    data: [],
    isLoaded: false
  }

  componentDidMount() {
    let d = {
      dept_code: this.props.data.dept_code,
      type: 'SYLLABUS'
    }

    PostData(`/getdepartmentfilebytype`, d)
      .then((resp) => {
        
        this.setState({
          data: resp,
          isLoaded: true
        })
      })
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  showFile = (blob, index) => {



    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);


    var link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
    link.click();




  }

  handleClick(data) {

    PostData(`/getfiledata`, data)
      .then((resp) => {

        //console.log(resp)
        this.showFile(resp, 1)
      })

  }

  modify_name = (name) => {
    return name.slice(0, 15) + '..';
  }
  render() {
    return (
      <div>
        <Container>
          <TitleStyle
            title={`Syllabus`}
          />

          <br />

          {this.state.isLoaded ? (
            <Grid container spacing={2}>

              {this.state.data.map((el, index) =>

                <Grid item key={index} xs={12} sm={12} lg={4} md={4}>
                  <Card>
                    <CardContent>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                      >
                        {el.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: el.description }}
                      >
                      </Typography>
                    </CardContent>

                    {Array.isArray(el.upload_info) ? (
                      <CardActions>
                        {el.upload_info.map((el1, index1) =>
                          <Button
                            size="small"
                            key={index1}
                            onClick={this.handleClick.bind(this, el1)}
                            color="primary"

                          >
                            <Icon color="primary" style={{ fontSize: 15 }}>file_download_outline</Icon> {this.modify_name(el1.path)}
                          </Button>
                        )}
                      </CardActions>
                    ) : null}

                  </Card>
                </Grid>

              )}

            </Grid>
          ) : <Typography>Loading . . . </Typography>}
        </Container>
      </div>
    )
  }
}
