import { Fab, makeStyles, Zoom } from '@material-ui/core'
import React from 'react'



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F89E2E',
    color: 'white',
    marginRight: 2,
    "&:hover": {
      backgroundColor: 'white',
      color: '#F89E2E'
    }
  }
}))

export default function FloatingAdmission({ session }) {


  const classes = useStyles();

  return (
    <React.Fragment>
      <Zoom in={true} timeout={500}>
        <div
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 999,
          }}
        >
          {Array.isArray(session) && session.map((el,index) => {
            if (el.status == true) {
              return (
                <Fab key={index}
                  variant="extended"
                  className={classes.root}
                  href={el.link}
                  target="_blank"
                >
                  {el.session}
                </Fab>


              )
            }
          })}
        </div>
      </Zoom>
    </React.Fragment>
  )
}
