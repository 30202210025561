import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';
import Syllabus from './Syllabus'
class CoursePage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Courses`}
                    />

                    <br />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: this.props.data.courses }}
                    >

                    </Typography>
                </Container>


                <Syllabus
                    {...this.props}
                    data={this.props.data}
                />
            </div>
        );
    }
}

export default CoursePage;