import React, { Component } from 'react';
import { Container, Typography, Grid } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class ContactPage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Contact`}
                    />

                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={6} md={6}>

                            <Typography
                                dangerouslySetInnerHTML={{ __html: this.props.data.contact_details }}
                            >

                            </Typography>

                        </Grid>

                        <Grid item xs={12} sm={12} lg={6} md={6}>

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.4784068593412!2d91.78207981502891!3d26.148544583462566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5922a0100723%3A0x48c22d59955cd557!2sDispur%20College!5e0!3m2!1sen!2sin!4v1647254284743!5m2!1sen!2sin" width="600" height="400" allowfullscreen="" loading="lazy" style={{border: '0px',maxWidth:'100%'}}></iframe>

                        </Grid>
                    </Grid>

                   
                </Container>
            </div>
        );
    }
}

export default ContactPage;