import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { PostData } from "../api/service";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'

export default class AntiRaggingPage extends Component {

  
  render() {
    return (
      <div className="my-4">
        <Container style={{minHeight: '80vh'}}>
          <div className="row">
            
           
            <div className="col-md-12 pl-2 py-2">
              <h1>Anti-Ragging Committee & Regulations</h1>
             
             <div>Objective:- <strong>To ensure a safe and secured environment for the newly admitted students</strong></div>
                <br />
             <div>
             Functions: -
             <ol>
                <li>To ensure recommendations of Lyngdoh Commission upheld by Honorable Supreme Court of India to be followed strictly. </li>
                <li>To visit the college campus frequently to ensure that new students are not harassed by the seniors. </li>
                <li>To inform new students in their respective classrooms to lodge complaint in the cell if any incident of ragging takes place. </li>
                <li>To take proper disciplinary action against the students involved in such act if any complaint received.</li>
                <li>To organize different activities, talks, street plays etc.at the time of new admission for motivating students not to indulge in any activity related to ragging. </li>
             </ol>

             <br />

             <div>
                Members:-
                <ul>
                    <li>Dr Navajyoti Borah, - Chairperson </li>
                    <li>Dr Ajay Mitra - IQAC Coordinator Ex Officio Member- 7002118966</li>
                    <li>Dr Ajay Deka – Convenor- 9864015629</li>
                    <li>Dr Binoy Chetia – Member- 9954027795	</li>
                    <li>Mrs Dharitri Thakuria- Member-9101562197</li>
                    <li>Dr Kuladhar Patowary- Member- 9954066617</li>
                    <li>Dr Kumud C Nath- 9864039115</li>
                    <li>Ms Shabnam Yasmin - 8638874640</li>
                    <li>Sri Mintu Thakuria(office) – 9854293148</li>
                </ul>
             </div>
             <br />

             <div>All the admitted students must download the Anti Ragging Affidavits format and submit the same after properly filled up and singed by both the candidate and his/her parents.</div>

             <br />

             <a target="_blank" href="https://www.antiragging.in/affidavit_affiliated_form.php"><button  className="btn btn-primary">Anti Ragging Form</button></a>

             &nbsp;

             <a target="_blank" href="/pdf/dc_affidavit_antiragging.pdf"><button  className="btn btn-primary">AFFIDAVIT BY PARENT/ GUARDIAN for Dispur College</button></a>


             &nbsp;

<a target="_blank" href="/pdf/UGC Regulation AR (1).pdf"><button  className="btn btn-primary">UGC Regulation for Anti-Ragging</button></a>
             </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
