import React, { Component } from "react";
import { Container, MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";

export default class ContactUsContainer extends Component {
  render() {
    return (
      <div>
        {/* <BreadCumb
          title={`Contact us`}
          data={[
            {
              title: "Home",
              path: "/",
              isActive: false,
            },
            {
              title: "Contact Us",
              path: "/contactus",
              isActive: true,
            },
          ]}
        />
        baad me sochega
         */}
        <Container style={{minHeight: '600px'}}>
          <br></br>
          <div>
            <MDBRow>
              <MDBCol lg="4" sm="12" md="4" xs="12" className="p-4">
                <ul>
                  <li style={{ paddingBottom: "",listStyle:'none',fontSize:'1.2em', marginTop:'3em'}}>
                    <h6>Postal Address</h6>
                  Dispur College
                    <br/>
                    Ganeshguri, Guwahati, Assam, India.
                    <br/>
                    Pin 781006
                    <br />
                    Email:{" "}
                    <a href={`mailto:dispurcollege1978@gmail.com`}>
                    dispurcollege1978@gmail.com
                    </a>
                    <br />
                    Webmail:{" "}
                    <a href={`mailto:dispurcollege1978@gmail.com`}>
                    dispurcollege1978@gmail.com
                    </a>
                    <br />
                    Website:{" "}
                    <a href={`http://dispurcollege.ac.in/`}>
                    http:/dispurcollege.ac.in/
                    </a>{" "}
                    <br />
                    Phone: <a href={`tel:03613513202`}>0361 - 3513202</a> , <a href={`tel:0361 3515332`}>0361 - 3515332</a>
                    {/* <br />
                    Fax: <a href={`tel:03612570450`}>0361 - 2340530</a> , <a href={`tel:03612340530`}> 0361 - 2673698</a> */}
                  </li>
                </ul>
              </MDBCol>
              <MDBCol
                lg="8"
                sm="12"
                md="8"
                xs="12"
                className="p-3"
                style={{ textAlign: "center" }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.4784068593412!2d91.78207981502891!3d26.148544583462566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5922a0100723%3A0x48c22d59955cd557!2sDispur%20College!5e0!3m2!1sen!2sin!4v1647254284743!5m2!1sen!2sin"
                  width="600"
                  height="400"
                  style={{border:0,maxWidth:'100%'}}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </MDBCol>
            </MDBRow>
          </div>
        </Container>
      </div>
    );
  }
}
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.4784068593412!2d91.78207981502891!3d26.148544583462566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5922a0100723%3A0x48c22d59955cd557!2sDispur%20College!5e0!3m2!1sen!2sin!4v1647254284743!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}