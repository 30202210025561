import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BreadCumb from "./../component/Department/BreadCumb";
import NotificationDescription from "../component/Notifications/NotificationDescription";
import { Container, Card, CardActions, CardContent, Grid, List, ListItem, Typography } from "@material-ui/core";
import { PostData } from "../api/service";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ShowImage from "../component/Department/ShowImage";
import { Menu, Button } from "antd";
import { Link } from "react-router-dom";
const handleDragStart = (e) => e.preventDefault();
const list = [
  {
    year: 2024,
  },
  {
    year: 2023,
  },
  {
    year: 2022,
  },
  {
    year: 2021,
  },
  {
    year: 2020,
  },
  {
    year: 2019,
  },
  
]

export default class Events extends Component {

  state = {
    data: [],
    isLoaded: false,
    year: '',
  }

  __loadFile = (year) => {

    this.setState({
      isLoaded: false
    })

    let d = {
      dept_code: 'DISPUR',
      type: 'COLLEGE_EVENTS',
      year: year
    }

    PostData(`/getcollegeeventlistwithyear`, d)
      .then((resp) => {
        this.setState({
          data: resp,
          year: year,
          isLoaded: true
        })
      })
  }

  componentDidMount() {
    this.__loadFile();
  }

  setYear = (year) => {
    this.__loadFile(year)
  }

  getImages = (upload_info) => {
    let im = [];
    if (Array.isArray(upload_info) && upload_info.length > 0) {
      let ut = upload_info;

      return (
        <AliceCarousel
          responsive={{
            0: {
              items: 1,
              itemsFit: "cover",
            },
            1024: {
              items: 1,
              itemsFit: "cover",
            },
          }}
          autoPlay={true}
          autoWidth={true}
          autoHeight={true}
          autoPlayInterval={2000}
          dotsDisabled={true}
          mouseTracking
          items={ut.map((el, index) => {
            return (
              <img
                src={el.url}
                onDragStart={handleDragStart}
                role="presentation"
                style={{ width: "100%", height: "400px", objectFit: "cover" }}
              />
            );
          })}
        />
      );
    }
    return <MDBRow style={{height: "400px"}}>{im}</MDBRow>;
  };

  render() {
    return (
      <div >
        
        <div className="my-4" style={{ minHeight: '500px' }}>
          <Container>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: "1em" }}
              >
                <Card
                  style={{
                    boxShadow: "none",
                    background: "",
                    borderRadius: "none",
                  }}
                >
                  <Typography variant="h6" style={{ margin: "1em 0" }} gutterBottom>
                    Event Archives
                  </Typography>
                  <Menu mode="horizontal">
                    {list.map((el, index) => (
                      <Menu.Item
                        button
                        // style={{
                        //     border: "1px solid #0002",
                        //     marginBottom: "2px",
                        //     backgroundColor: el === this.state.com ? '#F5EB3D' : '#f9f9f9'
                        // }}
                        key={index}
                        onClick={() => this.setYear(el.year)}
                        style={{
                          //backgroundColor: el.link === `/iqac/${this.state.link}` ? '#F5EB3D' :''
                        }}
                      >

                        {el.year}

                      </Menu.Item>
                    ))}
                  </Menu>

                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>



                <br />
                {this.state.isLoaded ? (
                  <Grid container spacing={2}>
                    {this.state.data.map((el, index) =>
                      <Grid item key={index} xs={12} sm={12} lg={12} md={12} style={{backgroundColor: "#f9f9f9", marginBottom: 10}}>
                        
                     
                        <Card>
                        <Grid container spacing={2}>
                        <Grid item key={index} xs={12} sm={12} lg={4} md={4}>
                        <div style={{ flexBasis: "40%" }}>
                            {this.getImages(el.upload_info)}
                          </div>
                        </Grid>
                        <Grid item key={index} xs={12} sm={12} lg={8} md={8}>
                        <CardContent style={{ minHeight: 80 }}>
                            <Typography
                              variant="h6"
                              style={{ fontSize: 18, fontWeight: 700, color: "#2C3E50" }}
                              gutterBottom
                            >
                              {el.title}
                            </Typography>
                            
                            <Typography
                              variant="body2"
                              dangerouslySetInnerHTML={{ __html: el.description }}
                            >
                            </Typography>

                            {Array.isArray(el.upload_info) ? (
                              <div>
                                {el.upload_info.map((el1, index1) =>
                                  <Button
                                    size="small"
                                    key={index1}
                                    //onClick={this.handleClick.bind(this, el1)}
                                    color="primary"
                                    target="_blank"
                                    href={el1.url}
                                    style={{marginRight: 10}}

                                  >
                                    View File
                                  </Button>
                                )}
                              </div>
                            ) : null}
                          </CardContent>
</Grid>
                        </Grid>
                          
                          
                          {/* <CardActions>
                          <Link to={`/singleevent?eventId=${el.id}`}>
                              <Button type="primary">View Event</Button>
                            </Link>
                          </CardActions> */}



                        </Card>
                      </Grid>

                    )}

                    { }

                    {
                      this.state.data.length === 0 || this.state.data.length == 'undefined' ? (
                        <Typography>No Events found</Typography>
                      ) : null
                    }

                  </Grid>
                ) : <Typography>Loading . . . </Typography>}

              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}
