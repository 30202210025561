import React from "react";

import { Chart } from "react-google-charts";

import { Tabs } from "antd";

const { TabPane } = Tabs;

export const data = [
  ["Session", "BA", "BCOM", "BBA", "BCA"],
  ["2018-19", 100, 100, 100, 96],
  ["2019-20", 93.3, 100, 100, 97.4],
  ["2020-21", 95, 98.35, 100, 100],
  ["2021-22", 97.42, 97.6, 100, 100],
  ["2022-23", 92.67, 96.24, 96, 98.03],
];



export function getSpecificData(column) {
    const columnIndex = data[0].indexOf(column);
    if (columnIndex === -1) {
      throw new Error(`Column "${column}" not found`);
    }
  
    const result = data.map(row => [row[0], row[columnIndex]]);
    return result;
  }


export default function ProgressionGraph() {
  return (
    <div>
      <Tabs defaultActiveKey="BA">
        <TabPane tab="BA" key="BA">
        <Chart
        chartType="ColumnChart"
        width="90%"
        height="500px"
        data={getSpecificData("BA")}
        options={{
          vAxis: {
            title: "Percentage",
            format: "decimal", // You can customize the format as needed
            viewWindow: {
              min: 0, // Adjust as needed based on your data
            },
          },
          hAxis: {
            title: "Session",
          },
        }}
      />
        </TabPane>
        <TabPane tab="BCOM" key="BCOM">
        <Chart
        chartType="ColumnChart"
        width="90%"
        height="500px"
        data={getSpecificData("BCOM")}
        options={{
          vAxis: {
            title: "Percentage",
            format: "decimal", // You can customize the format as needed
            viewWindow: {
              min: 0, // Adjust as needed based on your data
            },
          },
          hAxis: {
            title: "Session",
          },
        }}
      />
        </TabPane>
        <TabPane tab="BBA" key="BBA">
        <Chart
        chartType="ColumnChart"
        width="90%"
        height="500px"
        data={getSpecificData("BBA")}
        options={{
          vAxis: {
            title: "Percentage",
            format: "decimal", // You can customize the format as needed
            viewWindow: {
              min: 0, // Adjust as needed based on your data
            },
          },
          hAxis: {
            title: "Session",
          },
        }}
      />
        </TabPane>
        <TabPane tab="BCA" key="BCA">
        <Chart
        chartType="ColumnChart"
        width="90%"
        height="500px"
        data={getSpecificData("BCA")}
        options={{
          vAxis: {
            title: "Percentage",
            format: "decimal", // You can customize the format as needed
            viewWindow: {
              min: 0, // Adjust as needed based on your data
            },
          },
          hAxis: {
            title: "Session",
          },
        }}
      />
        </TabPane>

        <TabPane tab="ALL" key="ALL">
        <Chart
        chartType="Bar"
        width="90%"
        height="500px"
        data={data}
        options={{
          vAxis: {
            title: "Pass Percentage",
            format: "decimal", // You can customize the format as needed
            viewWindow: {
              min: 200, // Adjust as needed based on your data
            },
          },
          hAxis: {
            title: "Academic Session",
          },
        }}
      />
        </TabPane>
      </Tabs>

     
    </div>
  );
}
