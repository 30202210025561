import React, { Component } from 'react'
import { Container, Badge } from 'mdbreact';
import { PostData } from '../api/service';
import {Link} from 'react-router-dom'


const style = {
  display: 'flex',
  flexDirection: 'row'
}

class FleshNews extends Component {
  state = {
    news: [],
    isLoaded: false
  }

  componentDidMount() {
    // perform the api call

    let d = {
      type: 'COLLEGE_ACADEMIC_NOTICE'
    }

    PostData(`/getnoticesbytype`, d)
      .then((resp) => {
      //console.log("🚀 ~ file: Fleshnew.js ~ line 27 ~ FleshNews ~ .then ~ resp", resp)

        this.setState({
          news: resp,
          isLoaded: true
        })

      })


  }
  render() {
    return (
      <div style={{marginTop: 10}}>
        <Container>

          {this.state.isLoaded ? (<marquee>
            <div >
              <Link to="/notifications/academic">
                <div style={style}>

                {this.state.news.map((el, index) =>
                  <div style={{ paddingRight: '20px', fontWeight: 700, fontSize: '1.1em' }}>
                    <Badge color="danger">New</Badge> {el.title}
                  </div>
                )}
              </div>
              </Link>
            </div>
          </marquee>) : null}
        </Container>
      </div>
    );
  }
}
export default FleshNews;