import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MDBContainer } from "mdbreact";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function CollegeAnthem() {
  return (
    <div style={{ minHeight: 150, backgroundColor: "#151861" }}>
      <MDBContainer>
        <Grid container style={{height: 150}} spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={6} style={{textAlign: "center"}}>
            <Typography style={{fontFamily: "Dancing Script", fontSize: "2.2rem", color: "white"}}>
            Play Dispur College Anthem
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <AudioPlayer
              autoPlay={false}
              style={{width: '300px', borderRadius: 10, backgroundColor: "#06456B", color: "white"}}
              src="/settings/dispur-anthem.mpeg"
              onPlay={(e) => console.log("onPlay")}
              // other props here
            />
          </Grid>
        </Grid>
      </MDBContainer>
    </div>
  );
}
