import React, { Component } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { PostData } from '../../api/service';

class GalleryDescription extends Component {
    render() {
        return (
            <div>
                {this.props.type == 'photo' ? (<SiteMap title={`Photo Gallery`} type={`COLLEGE_PHOTO_GALLERY`} />) : null}
                {this.props.type == 'document' ? (<SiteMap1 title={`Document Gallery`} type={`COLLEGE_DOWNLOADS`} />) : null}
                
            </div>
        );
    }
}

export default GalleryDescription;

function SiteMap({title, type}){
    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);


    function fetchList(){
        let d = {
            type: 'COLLEGE',
            dept: 'DISPUR'
        }

        PostData(`/getgallery`, d)
            .then((resp) => {
                
                setData(resp);
                setIsLoaded(true)

            })
    }


    React.useState(() => {
        fetchList();
    }, [])

  

    return (
        <div>
            {isLoaded ? <React.Fragment>

<Grid container spacing={2}>

{Array.isArray(data) && data.map((el, index) =>

<Grid item key={index} xs={12} sm={12} lg={12} md={12}>

<Typography
    variant="h5"
    gutterBottom
    
>
    {el.caption}
</Typography>


{Array.isArray(el.upload_info) ? <ImageGallery items={el.upload_info.map((el1) => ({
    original: el1.url,
    thumbnail: el1.url
}))} /> : null}

<Divider />
</Grid>

)}

</Grid>

</React.Fragment> : <Typography>Loading . . . .</Typography>}

        </div>
    )
}



function SiteMap1({title, type}){
    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);


    function fetchList(){
        let d = {
            type: type,
            dept_code: 'DISPUR'
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {
                
                setData(resp);
                setIsLoaded(true)

            })
    }


    React.useState(() => {
        fetchList();
    }, [])
   

    function showImages(upload_info){
        let i = [];
        return <Grid container spacing={2}>
            
            {upload_info.map((el,index) => 
                <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                    {el.fileType === 'image' && <img
                        alt="image"
                        src={el.url}
                        style={{width: '100%'}}
                    />}

                    {el.fileType === 'PDF' && <a href={el.url} target="_blank">Download File</a>}
                </Grid>
                )}
            
        </Grid>
        
    }

  

    return (
        <div>
            <Typography
                variant="h5"
                gutterBottom
                style={{color: 'blue', fontWeight: 700}}
            >
               {title}
            </Typography>

            <br />

            {isLoaded ? <React.Fragment>

                <Grid container spacing={2}>

{Array.isArray(data) && data.map((el, index) =>

    <Grid item key={index} xs={12} sm={12} lg={12} md={12}>
        
                <Typography
                    variant="h6"
                    
                >
                    {el.title}
                </Typography>
               

            {Array.isArray(el.upload_info) ? showImages(el.upload_info) : null}

        <Divider />
    </Grid>

)}

</Grid>

            </React.Fragment> : <Typography>Loading . . . .</Typography>}

            
        </div>
    )
}

