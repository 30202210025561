import React, { Component } from 'react';
import { Container, Grid, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import NavigateNext from '@material-ui/icons/NavigateNext'
import IqacDescription from './GalleryDescription'
import BreadCumb from '../Department/BreadCumb';

const list = [
    {
        title: 'Photo Gallery',
        link: '/gallery/photo'
    },
    {
        title: 'Document Gallery',
        link: '/gallery/document'
    }
]

class GalleryComponent extends Component {
    state = {
        link: '',
    }
    componentDidMount() {
        let link = this.props.match.params.link;
        this.setState({
            link: link
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.link !== this.props.match.params.link) {
            let link = this.props.match.params.link;
            this.setState({
                link: link
            })
        }
    }


    render() {
        return (
            <div>
               
                <br />
                <Container style={{minHeight: '80vh'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Card style={{ boxShadow: 'none' }}>
                                <List component="nav">
                                    {list.map((el, index) => <ListItem button key={index} onClick={() => this.props.history.push(el.link)}>
                                        <ListItemText primary={el.title} />
                                        <ListItemSecondaryAction>
                                            <NavigateNext />
                                        </ListItemSecondaryAction>
                                    </ListItem>)}

                                </List>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <IqacDescription
                                type={this.state.link}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default GalleryComponent;