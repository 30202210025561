import React, { Component } from 'react';


class IqacMember extends Component {
    render() {
        let persons = this.props.data;
        let items = persons.map((person) =>
            <tr key={person.id}>
                <td>{person.name}</td>
                <td>{person.designation}</td>
                {/* <td>{person.section}</td> */}
                {/* <td>{person.phone}</td>
                <td>{person.email}</td> */}
            </tr>
        );
        return (
            <div className="container" style={{overflowX: 'scroll'}}>
                
                <table className="table table-bordered table-striped table-sm">
                    <thead>
                        <tr className="bg-primary text-white">
                            <td>Name</td>
                            <td>Designation</td>
                            {/* <td>Section</td> */}
                            {/* <td>Phone</td>
                            <td>Email</td> */}
                        </tr>
                    </thead>

                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default IqacMember;