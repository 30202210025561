import React from "react";
import { CardActionArea, Grid, Typography } from "@material-ui/core";
import SubscriptionModal from "../Essentials/SubscriptionModal";
import { MDBContainer } from "mdbreact";
import { Link } from "react-router-dom";
import {FacebookOutlined, YoutubeOutlined, InstagramOutlined} from '@ant-design/icons'
import { WebCounter } from "../Essentials/WebCounter";
import {Button} from 'antd'

export default function PanduFooter() {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  

  return (
    <div>
      {/* <SubscriptionModal isModalVisible={isModalVisible}  setIsModalVisible={setIsModalVisible}/> */}
      <div className="footer p-4">
        <MDBContainer>
          <div className="row">
            <div
              className="col-md-3 col-lg-3 col-xs-12 col-sm-12 mt-4"
              //style={{alignItems: "center" }}
            >
              <div className="row" style={{ padding: 0 }}>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12" style={{backgroundColor: "white"}}>
                  <img
                    src={"/newimages/dc.png"}
                    alt="logo"
                    style={{ width: "100%", padding: "0" }}
                  />
                </div>

                <div
                  className="col-md-8 col-lg-8 col-sm-12 col-xs-12"
                  // style={{
                  //   flexBasis: "auto",
                  //   margin: "15px",
                  //   padding: "0 10px",
                  //   borderRight: "3px solid #251d25",
                  //   color: '#EBDB7C'
                  // }}
                >
                  <div className="h6" style={{color: "#F89E2E"}}>Dispur College</div>
                  <p>Guwahati -781 006 , Assam, India.</p>
                  <p>0361 - 3513202, 0361 - 3515332</p>
                  <p>dispurcollege1978@gmail.com</p>
                  
                </div>
                <div style={{border: `solid 1px white`, padding: 10, width: '100%', marginTop: 20}}>
                  {/* Total Visitor Allow */}
                
              <WebCounter />

              <br />

              <div style={{textAlign: "center"}}>
              <Button href="/visitors-note" type="dashed">Submit Visitors' Note</Button>
              </div>
                
              </div>
              </div>
            </div>

            <div className="col-md-3 mt-4 add">
              <div className="h6" style={{color: "#F89E2E"}}>Important Links</div>
              <p>
                <a target="_blank" style={{color: "white"}} href="https://www.education.gov.in/en">
                  {" "}
                  Ministry of Education{" "}
                </a>{" "}
                |
                <a target="_blank" style={{color: "white"}} href="https://www.ugc.ac.in/">
                  {" "}
                  University Grants Commission{" "}
                </a>{" "}
                |
                <a target="_blank" style={{color: "white"}} href="https://nad.gov.in/">
                  {" "}
                  National Academic Depository{" "}
                </a>{" "}
                |
                <a target="_blank" style={{color: "white"}} href="https://dst.gov.in/">
                  {" "}
                  Ministry Of Science And Technology{" "}
                </a>{" "}
                |
                <a target="_blank" style={{color: "white"}} href="https://swayam.gov.in/">
                  {" "}
                  Swayam{" "}
                </a>{" "}
                |
                <a target="_blank" style={{color: "white"}} href="https://antiragging.in/">
                  {" "}
                  antiragging.in{" "}
                </a>{" "}
                |
              </p>
              <br />
              <div className="h6" style={{color: "#F89E2E"}}>Social Links</div>
              <div style={{display:"flex", width: "100%", justifyContent: "flex-start", alignItems: "center"}}>
                <a target="_blank" style={{color: "white"}} href="https://www.facebook.com/Dispurcollege1978?mibextid=ZbWKwL">
                  <div style={{marginRight: 20, display: "flex", justifyContent: "center",alignItems: "center"}}><FacebookOutlined /> &nbsp; Facebook</div>
                </a>
                <a target="_blank" style={{color: "white"}} href="https://instagram.com/dispurcollege?utm_source=qr&igshid=OGIxMTE0OTdkZA==">
                  <div style={{marginRight: 20, display: "flex", justifyContent: "center",alignItems: "center"}}><InstagramOutlined /> &nbsp; Instagram</div>
                </a>
                <a target="_blank" style={{color: "white"}} href="https://www.youtube.com/@DispurCollege">
                  <div style={{display: "flex", justifyContent: "center",alignItems: "center"}}><YoutubeOutlined /> &nbsp; Youtube</div>
                </a>
              </div>
            </div>
            <div className="col-md-2 mt-4 add">
              <div className="h6" style={{color: "#F89E2E"}}>Resources</div>
              <p>
                <a
                  target="_blank"
                  style={{color: "white"}}
                  href={`/pdf/Student_verification_20220531_0001.pdf`}
                >
                  Student Verification
                </a>
              </p>
              <p >
                <Link to="/notifications/downloads" style={{color: "white"}}>Academic Calender</Link>
              </p>
              <p>
                <Link to="/notifications/downloads" style={{color: "white"}}>Holiday List</Link>
              </p>
              <p>
                <Link to="/notifications/downloads" style={{color: "white"}}>College Magazine</Link>
              </p>
              <p>
                <Link to="/notifications/downloads" style={{color: "white"}}>College Prospectus</Link>
              </p>
              <p>
                <Link to="/gallery/photo" style={{color: "white"}}>Gallery</Link>
              </p>
              <p>
                <Link to="/gallery/document" style={{color: "white"}}>Document Gallery</Link>
              </p>
              
            </div>

            <div className="col-md-2 mt-4 add">
              <div className="h6" style={{color: "#F89E2E"}}>Portals</div>
              
              <p >
                <a href="https://admission.dispurcollege.ac.in" style={{color: "white"}}>Admission Portal</a>
              </p>
              <p>
                <a href="https://user.dispurcollege.ac.in" style={{color: "white"}}>Teacher's Portal</a>
              </p>
              <p>
                <a href="https://student.dispurcollege.ac.in" style={{color: "white"}}>Students' Portal</a>
              </p>
              <p >
                <a href="https://complaints.dispurcollege.ac.in" style={{color: "white"}}>Complaints Management</a>
              </p>
              <p>
                <a href="https://dheonlineadmission.amtron.in/sp/" style={{color: "white"}}>DHE Unique ID Portal</a>
              </p>
              <p>
                <a href="https://guportal.in/" style={{color: "white"}}>GU Portal</a>
              </p>
              <p>
                <a href="https://assamadmission.samarth.ac.in/" style={{color: "white"}}>Samarth eGov Suite</a>
              </p>
            </div>
            <div className="col-md-2 mt-4 add">
              <div className="h6" style={{color: "#F89E2E"}}>Location</div>
              
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.4784068593412!2d91.78207981502891!3d26.148544583462566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a5922a0100723%3A0x48c22d59955cd557!2sDispur%20College!5e0!3m2!1sen!2sin!4v1647254284743!5m2!1sen!2sin"
                  width="600"
                  height="200"
                  style={{border:0,maxWidth:'100%'}}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
            </div>
          </div>
        </MDBContainer>
      </div>

      <div
        className="row px-4 py-2 bg-violet d-flex"
        style={{
          background: "black",
          justifyContent: "space-between",
          margin: "0",
        }}
      >
        <MDBContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="text-white font-weight-light">
              ©2022 Dispur College
            </div>
            <div className="text-white font-weight-light">
              Designed & Developed With &#x2764; by Corexx
            </div>
          </div>
        </MDBContainer>
      </div>
    </div>
  );
}
