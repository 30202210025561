import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { Menu } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { BackTop, Card, Tabs } from "antd";
const { TabPane } = Tabs;
const { SubMenu } = Menu;

class DepartmentNavigation extends Component {
  state = {
    current: "",
  };

  checkIfEnoughtContent = (type) => {
    let d = this.props.data;

    if (d[type] == null) {
      return false;
    } else {
      if (d[type].length < 20) {
        return false;
      }
    }

    return true;
  };

  checkIfEnoughtContentCount = (type) => {
    let counts = this.props.data.counts;
    if (Array.isArray(counts)) {
      if (counts.length > 0) {
        let d = counts.find((el) => el.type == type);
        if (d) {
          return true;
        }
      }
    }

    return false;
  };

  checkIfEnoughtContentCountGallery = (type) => {
    let count_gallery = this.props.data.count_gallery;
    if (Array.isArray(count_gallery)) {
      if (count_gallery.length > 0) {
        let d = count_gallery.find((el) => el.type == type);
        if (d) {
          return true;
        }
      }
    }

    return false;
  };

  checkIfEnoughtContentCountEvents = (type) => {
    let count_notice = this.props.data.count_notice;
    if (Array.isArray(count_notice)) {
      if (count_notice.length > 0) {
        let d = count_notice.find((el) => el.type == type);
        if (d) {
          return true;
        }
      }
    }

    return false;
  };

  checkIfEnoughtContentCountAlumni = (type) => {
    let count_alumni = this.props.data.count_alumni;
    if (Array.isArray(count_alumni)) {
      if (count_alumni.length > 0) {
        let d = count_alumni.find((el) => el.type == type);
        if (d) {
          return true;
        }
      }
    }

    return false;
  };

  checkIfEnoughtContentCountSeminar = (type) => {
    let count_seminar = this.props.data.count_seminar;
    if (Array.isArray(count_seminar)) {
      if (count_seminar.length > 0) {
        let d = count_seminar.find((el) => el.type == type);
        if (d) {
          return true;
        }
      }
    }

    return false;
  };

  checkIfEnoughtContentCountQuestionBank = (type) => {
    let count_question = this.props.data.count_question;
    if (Array.isArray(count_question)) {
      if (count_question.length > 0) {
        let d = count_question.find((el) => el.type == type);
        if (d) {
          if(d.cc > 0){
            return true;
          }
          
        }
      }
    }

    return false;
  };

  // handleClick = (e) => {
  //     this.props.history.push(e.key);
  //     this.setState({
  //       current: e.key
  //     })
  //   }
  render() {
    let menu = [
      {
        title: "ABOUT",
        path: `/department/${this.props.dept_code}`,
        hasSubnav: false,
        isHidden: false,
        subnav: [],
      },
      {
        title: "VISION & MISSION",
        path: `/department/${this.props.dept_code}/profile`,
        hasSubnav: false,
        isHidden: false,
        subnav: [],
      },
      // {
      //     title: 'VISION',
      //     path: `/department/${this.props.dept_code}/vision`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      // {
      //     title: 'DEPARTMENT AT A GLANCE',
      //     path: `/department/${this.props.dept_code}/glance`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "FACULTY",
        path: `/department/${this.props.dept_code}/faculty`,
        hasSubnav: false,
        isHidden: false,
        subnav: [],
      },
      // {
      //     title: 'STUDENT',
      //     path: `/department/${this.props.dept_code}/student`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "COURSES & PROGRAMMES",
        path: `/department/${this.props.dept_code}/course`,
        hasSubnav: false,
        isHidden: false,
        subnav: [],
      },
      // {
      //     title: 'SYLLABUS',
      //     path: `/department/${this.props.dept_code}/syllabus`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "PUBLICATIONS",
        path: `/department/${this.props.dept_code}/journal`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCount("JOURNAL"),
        subnav: [],
      },
      {
        title: "EVENTS & ACTIVITIES",
        path: `/department/${this.props.dept_code}/events`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCountEvents("DEPTEVENT"),
        subnav: [],
      },

      {
        title: "SELF-PACED LEARNING RESOURCES",
        path: `/department/${this.props.dept_code}/questionbank`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCountQuestionBank("QUESTION_BANK"),
        subnav: [],
      },
      {
        title: "WALL-MAGAZINE",
        path: `/department/${this.props.dept_code}/wall-magazine`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCount("WALL_MAGAZINE"),
        subnav: [],
      },

      {
        title: "ACHIEVEMENTS",
        path: `/department/${this.props.dept_code}/achievements`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCount("ACHIEVEMENTS"),
        subnav: [],
      },
      {
        title: "ACTIVITY",
        path: `/department/${this.props.dept_code}/activity`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContent("activity"),
        subnav: [],
      },
      {
        title: "SEMINAR/WORKSHOP",
        path: `/department/${this.props.dept_code}/seminar`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCountSeminar(this.props.dept_code),
        subnav: [],
      },
      {
        title: "RESEARCH",
        path: `/department/${this.props.dept_code}/research`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContent("online_classes"),
        subnav: [],
      },
      {
        title: "GALLERY",
        path: `/department/${this.props.dept_code}/gallery`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCountGallery("DEPARTMENT"),
        subnav: [],
      },
      {
        title: "ALUMNI",
        path: `/department/${this.props.dept_code}/alumni`,
        hasSubnav: false,
        isHidden: !this.checkIfEnoughtContentCountAlumni(this.props.dept_code),
        subnav: [],
      },
      {
        title: "CONTACT",
        path: `/department/${this.props.dept_code}/contact`,
        hasSubnav: false,
        isHidden: false,
        subnav: [],
      },
    ];
    return (
      <div>
        {/* <div style={{padding: '1px 10px 1px 10px'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '15vh',
                            width: '100%',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}

                        onClick={() => this.props.history.push('/')}
                    >
                        <div
                            style={{
                                align: 'center'
                            }}
                        >
                            <img
                                src={'/images/header/logo.png'}
                                style={{
                                    width: '70px'
                                }}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: '2vw'
                            }}
                        >
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name_as}, বাহনা মহাবিদ্যালয়</Typography>
                            {/* <Typography variant="subtitle2">বাহনা মহাবিদ্যালয়</Typography> *
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name}, PANDU College</Typography>
                            {/* <Typography variant="subtitle2">PANDU College</Typography> *
                        </div>
                    </div>
                </div> */}
        {/* <Navbar bg="dark" expand="lg" className="justify-content-center" style={{backgroundColor: '#001529 !important'}}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{filter: 'invert()'}} />
                    <Navbar.Collapse>
                        <Nav className="mr-auto">

                            {menu.map((el, index) =>
                                <Nav.Link key={index} className="text-white bg-dark"
                                    style={{
                                        fontSize: '1em'
                                    }}

                                    onClick={() => this.props.history.push(el.path)}
                                >{el.title}</Nav.Link>
                            )}

                        </Nav>

                    </Navbar.Collapse>
                </Navbar> */}

        <div style={{ marginTop: 10 }}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            tabPosition="left"
            onChange={(key) => this.props.history.push(key)}
          >
            {menu
              .filter((el) => el.isHidden == false)
              .map((el, index) => (
                <TabPane tab={el.title} key={el.path} />
              ))}
          </Tabs>
        </div>

        {/* <Menu theme="dark" selectedKeys={[this.state.current]} onClick={this.handleClick} mode="horizontal"
    // overflowedIndicator={
    //      <MenuUnfoldOutlined />
    // }
>
            {menu.map((el, index) => {
              if (el.hasSubnav) {
                return <SubMenu title={el.title} key={index}>
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {

                      return <SubMenu title={el1.title} key={index1}>
                        {el1.subnav.map((el2, index2) =>
                          <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                        )}
                      </SubMenu>


                    } else {
                      return <Menu.Item key={el1.path}>{el1.title}</Menu.Item>
                    }

                  })}



                </SubMenu>
              } else {
                return <Menu.Item key={el.path} style={{fontSize: '11px'}} >
                  {el.title}
                </Menu.Item>
              }
            }

            )}


          </Menu> */}
      </div>
    );
  }
}

export default DepartmentNavigation;
