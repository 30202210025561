import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  CardContent,
} from "@material-ui/core";
import { Tabs, Card, Input, Select, Button, message } from "antd";
import { CardHeader } from "@material-ui/core";
import { GetDataExtra, PostData, PostDataExtra } from "../../api/service";
import MUIDataTable from "mui-datatables";
import AttachmentIcon from "@material-ui/icons/Attachment";

const { Option } = Select;

class EContentDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "class_note" ? (
          <Library title="Class Notes" type={this.props.type} />
        ) : null}

        {this.props.type == "class_videos" ? (
          <Videos title="Class Videos" type={this.props.type} />
        ) : null}

        {this.props.type == "class_assignments" ? (
          <Assignments title="Class Assignments" type={this.props.type} />
        ) : null}

        {this.props.type == "class_note_requisition" ? (
          <Requisitions title="Class Assignments" type={this.props.type} />
        ) : null}
      </div>
    );
  }
}

export default EContentDescription;

export const Library = (props) => {
  const [data, setData] = React.useState([]);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    __loadData(0, 10, "");
  }, []);

  function __loadData(page, rowsPerPage, search) {
    setIsLoaded(false);
    setSearchText(search);

    GetDataExtra(
      `/get-all-class-notes-paginated?page=${page}&per_page=${rowsPerPage}&search=${search}`
    ).then((resp) => {
      setData(resp.data);
      setRowsPerPage(rowsPerPage);
      setPage(page);
      setCount(resp.count);
      setIsLoaded(true);
    });
  }

  // function __loadFile() {
  //   GetDataExtra(`/get-all-class-notes`).then((resp) => {
  //     console.log(resp);
  //     setData(resp);
  //     setIsLoaded(true);
  //   });
  // }

  const columns = [
    {
      name: "name",
      label: "Teacher",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "class_name",
      label: "Class Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_name",
      label: "Subject",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "upload_info_url",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              {Array.isArray(row) &&
                row.length > 0 &&
                row.map((el, index) => (
                  <a target="_blank" href={el}>
                    <AttachmentIcon style={{ fontSize: 20 }} /> View
                  </a>
                ))}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    responsive: "scroll",
    rowsPerPage: rowsPerPage,
    count: count,
    page: page,
    searchText: searchText,
    jumpToPage: true,
    textLabels: {
      body: {
        noMatch: !isLoaded ? (
          "Loading..... Please wait."
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action == "changePage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "changeRowsPerPage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "search") {
        setSearchText();
        if (tableState.searchText != null && tableState.searchText.length > 3) {
          __loadData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
        }
        if (tableState.searchText == null) {
          __loadData(tableState.page, tableState.rowsPerPage, "");
        }
      }
    },
  };

  return (
    <div className="text-justify">
      <br />
      <br />

      <MUIDataTable
        title={"Classnotes"}
        data={[
          ...data.map((el, index) => {
            return {
              ...el,
            };
          }),
        ]}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export const Videos = (props) => {
  const [data, setData] = React.useState([]);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    __loadData(0, 10, "");
  }, []);

  function __loadData(page, rowsPerPage, search) {
    setIsLoaded(false);
    setSearchText(search);

    GetDataExtra(
      `/get-all-class-videos-paginated?page=${page}&per_page=${rowsPerPage}&search=${search}`
    ).then((resp) => {
      setData(resp.data);
      setRowsPerPage(rowsPerPage);
      setPage(page);
      setCount(resp.count);
      setIsLoaded(true);
    });
  }

  // function __loadFile() {
  //   GetDataExtra(`/get-all-class-notes`).then((resp) => {
  //     console.log(resp);
  //     setData(resp);
  //     setIsLoaded(true);
  //   });
  // }

  const columns = [
    {
      name: "name",
      label: "Teacher",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "class_name",
      label: "Class Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_name",
      label: "Subject",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "link",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              <a target="_blank" href={row}>
                <AttachmentIcon style={{ fontSize: 20 }} /> Video
              </a>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    responsive: "scroll",
    rowsPerPage: rowsPerPage,
    count: count,
    page: page,
    searchText: searchText,
    jumpToPage: true,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !isLoaded ? (
          "Loading..... Please wait."
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onTableChange: (action, tableState) => {
      if (action == "changePage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "changeRowsPerPage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "search") {
        setSearchText();
        if (tableState.searchText != null && tableState.searchText.length > 3) {
          __loadData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
        }
        if (tableState.searchText == null) {
          __loadData(tableState.page, tableState.rowsPerPage, "");
        }
      }
    },
  };

  return (
    <div className="text-justify">
      <br />
      <br />

      <MUIDataTable
        title={"Class Videos"}
        data={[
          ...data.map((el, index) => {
            return {
              ...el,
            };
          }),
        ]}
        columns={columns}
        options={options}
      />
    </div>
  );
};

// export const Videos = (props) => {
//   const [data, setData] = React.useState([]);
//   const [isLoaded, setIsLoaded] = React.useState(false);

//   React.useEffect(() => {
//     __loadFile();
//   }, []);

//   function __loadFile() {
//     GetDataExtra(`/get-all-class-videos`).then((resp) => {
//       setData(resp);
//       setIsLoaded(true);
//     });
//   }
//   const options = {
//     filterType: "checkbox",
//     selectableRows: false,
//   }

//   const columns = [
//     {
//       name: "name",
//       label: "Teacher",
//       options: {
//         filter: true,
//         sort: true,
//       },
//     },
//     {
//       name: "class_name",
//       label: "Class Name",
//       options: {
//         filter: true,
//         sort: false,
//       },
//     },
//     {
//       name: "sub_name",
//       label: "Subject",
//       options: {
//         filter: true,
//         sort: false,
//       },
//     },
//     {
//       name: "title",
//       label: "Title",
//       options: {
//         filter: true,
//         sort: false,
//       },
//     },
//     {
//       name: "link",
//       label: "Files",
//       options: {
//         customBodyRender: (row, tableMeta, updateValue) => {
//           return (
//             <div>
//               <a target="_blank" href={row}>
//                 <AttachmentIcon style={{ fontSize: 20 }} /> Video
//               </a>
//             </div>
//           );
//         },
//       },
//     },
//   ];

//   return (
//     <div className="text-justify">
//       <br />
//       <br />

//       {isLoaded && (
//         <MUIDataTable
//           title={"Class Videos"}
//           data={[
//             ...data.map((el, index) => {
//               return {
//                 ...el,
//               };
//             }),
//           ]}
//           columns={columns}
//           options={options}
//         />
//       )}
//     </div>
//   );
// };

export const Assignments = (props) => {
  const [data, setData] = React.useState([]);

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    __loadData(0, 10, "");
  }, []);

  function __loadData(page, rowsPerPage, search) {
    setIsLoaded(false);
    setSearchText(search);

    GetDataExtra(
      `/get-all-class-assignments-paginated?page=${page}&per_page=${rowsPerPage}&search=${search}`
    ).then((resp) => {
      setData(resp.data);
      setRowsPerPage(rowsPerPage);
      setPage(page);
      setCount(resp.count);
      setIsLoaded(true);
    });
  }

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    responsive: "scroll",
    rowsPerPage: rowsPerPage,
    count: count,
    page: page,
    searchText: searchText,
    jumpToPage: true,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !isLoaded ? (
          "Loading..... Please wait."
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    onTableChange: (action, tableState) => {
      if (action == "changePage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "changeRowsPerPage") {
        __loadData(
          tableState.page,
          tableState.rowsPerPage,
          tableState.searchText
        );
      }
      if (action == "search") {
        setSearchText();
        if (tableState.searchText != null && tableState.searchText.length > 3) {
          __loadData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
        }
        if (tableState.searchText == null) {
          __loadData(tableState.page, tableState.rowsPerPage, "");
        }
      }
    },
  };

  const columns = [
    {
      name: "name",
      label: "Teacher",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "class_name",
      label: "Class Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sub_name",
      label: "Subject",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "last_date_m",
      label: "Last date of submission",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "upload_info_url",
      label: "Files",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              {Array.isArray(row) &&
                row.length > 0 &&
                row.map((el, index) => (
                  <a target="_blank" href={el}>
                    <AttachmentIcon style={{ fontSize: 20 }} /> View
                  </a>
                ))}
            </div>
          );
        },
      },
    },
    {
      name: "link",
      label: "Submit",
      options: {
        customBodyRender: (row, tableMeta, updateValue) => {
          return (
            <div>
              <a target="_blank" href={`https://student.dispurcollege.ac.in`}>
                Student Login
              </a>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="text-justify">
      <br />
      <br />

      <MUIDataTable
        title={"Class Assignments"}
        data={[
          ...data.map((el, index) => {
            return {
              ...el,
            };
          }),
        ]}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export const Requisitions = (props) => {
  const [teachers, setTeachers] = React.useState([]);
  const [isTeacherListLoaded, setIsTeacherListLoaded] = React.useState(false);

  function loadTeachers() {
    GetDataExtra(`/getemployeesmalldetails`).then((resp) => {
      setTeachers(resp);
      console.log("🚀 ~ GetDataExtra ~ resp:", resp)
      setIsTeacherListLoaded(true);
    });
  }

  React.useEffect(() => {
    loadTeachers();
  }, []);

  const [state, setState] = React.useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    teacher: "",
    course: "",
    semester: "",
    stream: "",
  });

  const [error, setError] = React.useState({
    name: "",
    phone: "",
    email: "",
    topic: "",
    teacher: "",
    course: "",
    semester: "",
    stream: "",
  });

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  function handleValidation() {
    let isValid = true;
    let err = [];

    if (state.name == "" || state.name == null) {
      isValid = false;
      err["name"] = "Mandatory Field";
    } else {
      err["name"] = "";
    }

    if (state.email == "" || state.email == null) {
      isValid = false;
      err["email"] = "Mandatory Field";
    } else {
      err["email"] = "";
    }

    if (state.phone == "" || state.phone == null) {
      isValid = false;
      err["phone"] = "Mandatory Field";
    } else {
      err["phone"] = "";
    }

    if (state.topic == "" || state.topic == null) {
      isValid = false;
      err["topic"] = "Mandatory Field";
    } else {
      err["topic"] = "";
    }

    if (state.teacher == "" || state.teacher == null) {
      isValid = false;
      err["teacher"] = "Mandatory Field";
    } else {
      err["teacher"] = "";
    }

    if (state.course == "" || state.course == null) {
      isValid = false;
      err["course"] = "Mandatory Field";
    } else {
      err["course"] = "";
    }

    if (state.semester == "" || state.semester == null) {
      isValid = false;
      err["semester"] = "Mandatory Field";
    } else {
      err["semester"] = "";
    }

    setError(err);
    return isValid;
  }

  function onClear() {
    setState({
      name: "",
      email: "",
      phone: "",
      topic: "",
      teacher: "",
      course: "",
      semester: "",
      stream: "",
    });
  }

  function onSubmit() {
    if (handleValidation()) {
      setIsSubmitting(true);

      PostDataExtra(`/requisitions/add`, {
        ...state,
      })
        .then((resp) => {
          message.success(resp);
          setIsSubmitting(false);
          onClear();
        })
        .catch((err) => {
          setIsSubmitting(false);
          message.error("Error in server");
        });
    }
  }

  return (
    <div className="text-justify">
      <br />
      <br />
      <Card>
        <CardHeader
          title="Class Notes Requisitions"
          subheader="Fillup the form to submit requisitions. You will receive the file in email provided herewith."
        />

        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Your Name <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.name}</div>
              <Input
                value={state.name}
                onChange={(e) => onChange("name", e.target.value)}
                placeholder="Name"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Your Email <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.email}</div>
              <Input
                type="email"
                value={state.email}
                onChange={(e) => onChange("email", e.target.value)}
                placeholder="Email Address"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Your Phone <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.phone}</div>
              <Input
                type="number"
                value={state.phone}
                onChange={(e) => onChange("phone", e.target.value)}
                placeholder="Phone"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Select Teacher <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.teacher}</div>
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                value={state.teacher}
                onChange={(e) => onChange("teacher", e)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option value="">--Select--</Option>
                {Array.isArray(teachers) &&
                  teachers
                    .filter((el) => el.type == "TEACHER")
                    .map((el, index) => (
                      <Option value={el.email}>{el.name}</Option>
                    ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Topic <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.phone}</div>
              <Input
                type="text"
                value={state.topic}
                onChange={(e) => onChange("topic", e.target.value)}
                placeholder="Topic"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Select Course <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.course}</div>
              <Select
                style={{ width: "100%" }}
                value={state.course}
                onChange={(e) => onChange("course", e)}
              >
                <Option value="">--Select--</Option>
                <Option value="BA">BA</Option>
                <Option value="BCOM">BCOM</Option>
                <Option value="HSA">HS Arts</Option>
                <Option value="HSC">HS Commerce</Option>
                <Option value="HSC">HS Commerce</Option>
                <Option value="BBA">BBA</Option>
                <Option value="BCA">BCA</Option>
              </Select>
            </Grid>

           

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div style={{ fontWeight: 700, marginBottom: 5 }}>
                Select {state.course == "HS" ? "Year" : "Semester"}{" "}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ color: "red" }}>{error.semester}</div>
              <Select
                style={{ width: "100%" }}
                value={state.semester}
                onChange={(e) => onChange("semester", e)}
              >
                <Option value="">--Select--</Option>
                <Option value="1">1st</Option>
                <Option value="2">2nd</Option>
                <Option value="3">3rd</Option>
                <Option value="4">4th</Option>
                <Option value="5">5th</Option>
                <Option value="6">6th</Option>
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Button
                style={{ marginTop: 26 }}
                loading={isSubmitting}
                type="primary"
                onClick={() => onSubmit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
