import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MDBContainer } from "mdbreact";
import AlumniSmall from "./AlumniSmall";
import ProgressionGraph from "./ProgressionGraph";

export default function GraphContainer() {
  return (
    <div>
      <br />
      <MDBContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
              Academic Progression
            </Typography>
            <ProgressionGraph />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
              Prominent Alumni
            </Typography>
            <AlumniSmall />
          </Grid>
        </Grid>
      </MDBContainer>
    </div>
  );
}
