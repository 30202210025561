import React, { Component, Suspense } from 'react';
import { Switch, Router, Route, BrowserRouter } from 'react-router-dom';
import HomepageContainer from './containers/HomepageContainer';
import history from './history';
import './App.css';
import DepartmentHome from './component/Department/DepartmentHome';
import ScrollToTop from './ScrollToTop';
import LazyLoader from './component/Essentials/LazyLoader';
import FloatingAdmission from './component/Essentials/FloatingAdmission';
import EContent from './component/EContent/EContent';
// const DepartmentHome = React.lazy(() => import('./component/Department/DepartmentHome'));

class App extends Component {

 
  state = {
    showAdmission: false,
    admissionSession: '',
    examinationSession: '',
    examinationLink: '',
    examinationLinkText: '',
    showExamination: false
  }

  componentDidMount() {
    window.scrollTo(0, 0)


    fetch(`/settings/admissionSettings.json`)
        .then((resp) => resp.json())
        .then((resp) => {
            
                this.setState({
                    showAdmission: true,
                    admissionSession: resp,
                    
                })
            
            
        }).catch((err) => {
            console.log(err)
        })

        
  }


  render() {
    return (
     <Router history={history}>
     
        {/* <Router history={history}> */}
          <div>

          {this.state.showAdmission && <FloatingAdmission session={this.state.admissionSession} />}

            <ScrollToTop>

              <Switch>
                <Route exact path="/" component={HomepageContainer} />
                <Route exact path="/administrations/:link" component={HomepageContainer} />
                <Route exact path="/aboutus/:link" component={HomepageContainer} />
                <Route exact path="/notifications/:link" component={HomepageContainer} />
                <Route exact path="/notifications/:link/:year" component={HomepageContainer} />
                <Route exact path="/events" component={HomepageContainer} />
                <Route exact path="/departments" component={HomepageContainer} />
                <Route exact path="/students/:link" component={HomepageContainer} />
                <Route exact path="/facility/:link" component={HomepageContainer} />
                <Route exact path="/iqac/:link" component={HomepageContainer} />
                <Route exact path="/iic/:link" component={HomepageContainer} />
                <Route exact path="/uba/:link" component={HomepageContainer} />
                <Route exact path="/nirf" component={HomepageContainer} />
                <Route exact path="/anti-ragging" component={HomepageContainer} />
                <Route exact path="/newsletter" component={HomepageContainer} />
                <Route exact path="/rti/:link" component={HomepageContainer} />
                <Route exact path="/alumni/:link" component={HomepageContainer} />
                <Route exact path="/contactus" component={HomepageContainer} />
                <Route exact path="/committees" component={HomepageContainer} />
                
                <Route exact path="/department/:dept_code" component={DepartmentHome} />
                
                <Route exact path="/departments/arts" component={HomepageContainer} />
                <Route exact path="/departments/science" component={HomepageContainer} />
                <Route exact path="/departments/commerce" component={HomepageContainer} />
                <Route exact path="/departments/others" component={HomepageContainer} />
                
                {/* <Route exact path="/department/:dept_code/vision" component={DepartmentHome} /> */}
                {/* <Route exact path="/department/:dept_code/glance" component={DepartmentHome} /> */}
                <Route exact path="/department/:dept_code/profile" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/course" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/contact" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/wall-magazine" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/questionbank" component={DepartmentHome} />
                {/* <Route exact path="/department/:dept_code/others" component={DepartmentHome} /> */}
                {/* <Route exact path="/department/:dept_code/onlineclasses" component={DepartmentHome} /> */}
                <Route exact path="/department/:dept_code/faculty" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/faculty/single" component={HomepageContainer} />
                <Route exact path="/department/:dept_code/syllabus" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/journal" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/seminar" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/events" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/achievements" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/alumni" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/activity" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/gallery" component={DepartmentHome} />
                <Route exact path="/department/:dept_code/research" component={DepartmentHome} />
                <Route exact path="/e-content/:link" component={HomepageContainer} />
                <Route exact path="/gallery/:link" component={HomepageContainer} />
                <Route exact path="/nss/:link" component={HomepageContainer} />
                <Route exact path="/e-content/:link" component={EContent} />
                <Route exact path="/publication/:link" component={HomepageContainer} />
                <Route exact path="/visitors-note" component={HomepageContainer} />
                {/* <Route exact path="/render-pdf" component={HomepageContainer} /> */}
                {/* <Route exact path="/news" component={HomepageContainer} /> */}

                {/* <Route exact path="/registration" component={HomepageContainer} />
                <Route exact path="/officestaff" component={HomepageContainer} />
                <Route exact path="/supportstaff" component={HomepageContainer} />
                <Route exact path="/librarystaff" component={HomepageContainer} /> */}

                {/* <Route exact path="/aboutus" component={HomepageContainer} /> */}

                
                {/* <Route exact path="/vision" component={HomepageContainer} />
                <Route exact path="/mission" component={HomepageContainer} />
                <Route exact path="/committee" component={HomepageContainer} />
                <Route exact path="/rti" component={HomepageContainer} />

                <Route exact path="/principal" component={HomepageContainer} />
                <Route exact path="/secretary" component={HomepageContainer} />
                <Route exact path="/viceprincipal" component={HomepageContainer} />
                <Route exact path="/coursecordinator" component={HomepageContainer} />
                <Route exact path="/gbpresident" component={HomepageContainer} />
                <Route exact path="/trimurti" component={HomepageContainer} />

                <Route exact path="/hods" component={HomepageContainer} />
                <Route exact path="/governingbody" component={HomepageContainer} />
                <Route exact path="/managementbody" component={HomepageContainer} />
                <Route exact path="/generalrule" component={HomepageContainer} />

                <Route exact path="/library/:link" component={HomepageContainer} />


                <Route exact path="/infrastructure/:link" component={HomepageContainer} />
                <Route exact path="/academic/:link" component={HomepageContainer} />
                <Route exact path="/others/:link" component={HomepageContainer} />
                <Route exact path="/tenders/:link" component={HomepageContainer} />
                
                <Route exact path="/facilities/:link" component={HomepageContainer} />
                <Route exact path="/achievements/:link" component={HomepageContainer} />
                <Route exact path="/focus/:link" component={HomepageContainer} />
                <Route exact path="/digital/:link" component={HomepageContainer} />
                <Route exact path="/innovatives/:link" component={HomepageContainer} />
                <Route exact path="/supports/:link" component={HomepageContainer} />
                <Route exact path="/gallery/:link" component={HomepageContainer} />
                <Route exact path="/extensions/:link" component={HomepageContainer} /> */}

                
              </Switch>
            </ScrollToTop>
          </div>
        
        {/* </Router> */}
      
      </Router>
      
     
    );
  }
}

export default App;
