import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCardText,
  MDBBtn,
} from "mdbreact";
import { Divider, Button } from "antd";
import "./css/homepagenews.css";
import { PostData } from "../../api/service";
import { LinkOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Util } from "reactstrap";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const newsArray = [
  {
    date: "25",
    month: "MARCH",
    headline: "Somehting about the news",
  },
  {
    date: "26",
    month: "MARCH",
    headline: "Somehting about the news",
  },
  {
    date: "27",
    month: "MARCH",
    headline: "Somehting about the news",
  },
];

export default class HomePageEvents extends Component {
  state = {
    news: [],
    isLoaded: false,
  };

  componentDidMount() {
    // perform the api call

    let d = {
      type: "COLLEGE_EVENTS",
    };

    PostData(`/getnoticesbytype`, d).then((resp) => {
      console.log(
        "🚀 ~ file: HomePageEvents.js:48 ~ HomePageEvents ~ .then ~ resp:",
        resp
      );

      this.setState({
        news: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <div className="py-4">
          {/* {this.state.isLoaded ? (
            <Carousel autoPlay={true}>
              {this.state.news.map((el, index) => {
                return (
                  <div key={index}>
                    <NewsCard1 {...el} />
                  </div>
                );
              })}
            </Carousel>
          ) : null} */}
          {this.state.isLoaded ? (
            <div>
            <Divider
              orientation="left"
              style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
            >
              RECENT EVENTS
            </Divider>
              <AliceCarousel
                responsive={{
                  0: {
                    items: 1,
                  },
                  1024: {
                    items: 2,
                    itemsFit: "contain",
                  },
                }}
                autoPlay={true}
                autoWidth={true}
                autoHeight={true}
                autoPlayInterval={2000}
                dotsDisabled={true}
                mouseTracking
                items={this.state.news.map((el, index) => {
                  return (
                    <>
                      <NewsCard1 {...el} />
                    </>
                  );
                })}
              />

              <div style={{textAlign:"right"}}>
                <Button href={`/events`} type="primary">View Events</Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const NewsCard = (props) => {
  return (
    <MDBCard className="m-2 p-2">
      <MDBRow>
        <MDBCol size="12">
          <div style={{ color: "grey", fontSize: "0.8em" }}>{props.date}</div>
          <h6
            align="justify"
            style={{
              fontWeight: 400,
              fontSize: "1.2em",
            }}
          >
            {props.title}
          </h6>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  );
};

const handleDragStart = (e) => e.preventDefault();

const NewsCard1 = (props) => {
  const getImages = () => {
    let im = [];
    if (Array.isArray(props.upload_info) && props.upload_info.length > 0) {
      let ut = props.upload_info;

      return (
        <AliceCarousel
          responsive={{
            0: {
              items: 1,
              itemsFit: "cover",
            },
            1024: {
              items: 1,
              itemsFit: "cover",
            },
          }}
          autoPlay={true}
          autoWidth={true}
          autoHeight={true}
          autoPlayInterval={2000}
          dotsDisabled={true}
          mouseTracking
          items={ut.map((el, index) => {
            return (
              <img
                src={el.url}
                onDragStart={handleDragStart}
                role="presentation"
                style={{ width: "100%", height: "400px", objectFit: "cover" }}
              />
            );
          })}
        />
      );
    }
    return <MDBRow style={{height: "400px"}}>{im}</MDBRow>;
  };
  return (
    <div className="m-1 p-1">
      <MDBRow>
        {/* <MDBCol size="12">
                    <MDBCardText
                        className="card-date-news">
                        <h6>{props.date_no}</h6>
                        <h6>{props.month}</h6>
                    </MDBCardText>
                </MDBCol> */}
        <MDBCol size="12" style={{ textAlign: "left" }}>
          {getImages()}
          <br />
          <div style={{ color: "grey", fontSize: "0.8em" }}>{props.date}</div>
          <b />
          <h5
            align="left"
            style={{
              fontWeight: 400,
              fontSize: "1.2em",
              fontWeight: 700,
              minHeight: 60
            }}
          >
            {props.title}
          </h5>
          <p dangerouslySetInnerHTML={{ __html: props.description }} style={{height: 150}}></p>
        </MDBCol>
      </MDBRow>
    </div>
  );
};
