import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';
import Achievements from './Achievements'
class Activity extends Component {
    render() {
        return (
            <div>
                <Container>


                    <br />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: this.props.data.activity }}
                   style={{textAlign:'justify'}} >

                    </Typography>
                </Container>


                <Achievements
                    {...this.props}
                    data={this.props.data}
                />
            </div>
        );
    }
}

export default Activity;