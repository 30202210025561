import React, { Component } from 'react'

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import { Card, CardHeader } from 'reactstrap';
import BreadCumb from '../Department/BreadCumb';
import { PostData } from '../../api/service';



// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]


export default class PrincipalContainer extends Component {

    state = {
        facultyData: [],
        isLoaded: false,
        dept_code: 'OFF'
      }
    
      componentDidMount() {
    
    
        let d = {
          dept_code: 'OFF',
          type: 'PRINCIPAL'
        }
    
        PostData(`/getemployeebytype`, d)
          .then((resp) => {
              //console.log(resp)
            this.setState({
                ...resp[0],
              facultyData: resp,
              isLoaded: true
            })
          })
      }
    render() {
        return (
            <div>

              

                <MDBContainer style={{ paddingTop: '20px' }}>

                    <br />

                    {this.state.isLoaded && this.state.facultyData.map((el, index) => {
                        return <div>
                            {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}

                            <div>
                            <img
                                                alt=""
                                                src={'/images/dc-principal-new.jpeg'}
                                                style={{ 
                                                    width: '100%', 
                                                    boxShadow: '1px 2px 10px rgba(0,0,0,0.1)',
                                                    border: `solid 5px wheat`
                                             }}
                                            />
                                <MDBRow className='mt-2'>
                                    
                                    <MDBCol lg='12' sm="12" xs="12" md="12">
                                        <div className="new-principal-div">
                                    <h5 style={{ textAlign: 'center', color: '#1E3760', fontSize: '1.5em', marginTop: '5vh' }}>Principal's Message</h5>
                                <p style={{textAlign:'justify',marginTop:'3vh',fontWeight: 500}}><i>Dear Students,</i></p>
                                <p style={{textAlign:'justify',marginTop:'3vh',fontWeight: 500}}><i>I would like to take this opportunity to heartily invite each one of you, who desires to gain knowledge and be an asset to the society and the nation to the premises of Dispur College. The College named under the capital of Assam has produced many eminent figures and we are still in the journey to continue producing many more eminent personalities who can make the state and the nation proud. As A.P.J. Abdul Kalam said “Every youth is Unique” we at Dispur College, aim to achieve holistic development of each and every student, so that tomorrow they can stand at their own feet and explore new ventures that await for them at every juncture of their life. As we all are aware of the fact that, an institution cannot shine only with the efforts of one person, so I humbly invite all of you to the process of taking the College to the pinnacle of success. The college shall forever look to address many grievances that a student might face in their journey of education and henceforth would try to implement as many apt and necessary steps as required, so that when they leave the institution they carry forth an individual identity of their own.The folk culture of the region in the form of art, dance, and creative writings has been made an integral part of campus life for the students. It would go a long way in preserving traditions and cultural ethos of this progressive state.</i>
                                    </p>
                                    <p style={{textAlign:'justify',marginTop:'3vh',fontWeight: 500}}><i> I therefore want to take the privilege to invite all of you to be a part of this prestigious institution and in the process unleash a new version of yourself. </i>
                                    </p>

                                    <p style={{textAlign:'justify',marginTop:'3vh',fontWeight: 500}}><i> As Rabindranath Tagore wrote, "Where the mind is without fear and knowledge is held high", the college shall forever uphold this motto and shall ignite the young minds to be the future  stars of our Nation building process. 
</i>
                                    </p>
                                   <br/>
                                   <div style={{ textAlign: 'right' }}>
                                            
                                      
                                        <br />
                                        <h5 style={{fontSize: '16px', fontWeight: 700}}>{el.name}</h5>
                                        <h6 style={{fontSize: '12px'}}>{el.qualification}</h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`mailto:${el.email}`}>{el.email}</a></h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`tel:${el.phone}`}>{el.phone}</a></h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`tel:${el.mobile}`}>{el.mobile}</a></h6>
                                        <h6 style={{fontSize: '12px'}}>{el.specifications}</h6>
                                   
                                        {this.state.isLoaded && this.state.id != undefined && el.liveNow != 0 && <a style={{color: '#1892FF', cursor: 'pointer', fontWeight: 700}} 
              href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}>Visit Profile</a>}

              <br />

              <a style={{color: '#1892FF', cursor: 'pointer', fontWeight: 700}} 
              href={`/notifications/principal_activity`}>Principal's Monthly Report</a>
                                      </div>
                                    </div>
                                    </MDBCol>

                                   

                                    {/* <MDBCol lg='12' md="12" sm="12" xs="12">
                                    <br />
                                    <br />
                                    <div style={{ textAlign: 'left' }}>
                                            <img
                                                alt=""
                                                src={'/images/principal_dispurcollege.jpeg'}
                                                style={{ 
                                                    width: '200px', 
                                                    boxShadow: '1px 2px 10px rgba(0,0,0,0.1)',
                                                    border: `solid 5px wheat`
                                             }}
                                            />
                                        </div>
                                        <br />
                                        <h5 style={{fontSize: '16px', fontWeight: 700}}>{el.name}</h5>
                                        <h6 style={{fontSize: '12px'}}>{el.qualification}</h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`mailto:${el.email}`}>{el.email}</a></h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`tel:${el.phone}`}>{el.phone}</a></h6>
                                        <h6 style={{fontSize: '12px'}}><a href={`tel:${el.mobile}`}>{el.mobile}</a></h6>
                                        <h6 style={{fontSize: '12px'}}>{el.specifications}</h6>
                                   
                                        {this.state.isLoaded && this.state.id != undefined && el.liveNow != 0 && <a style={{color: '#1892FF', cursor: 'pointer', fontWeight: 700}} 
              href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}>Visit Profile</a>}
                                    </MDBCol> */}
                                </MDBRow>
                                
                            </div>
                        </div>

                    })}






                </MDBContainer>

            </div>
        )
    }
}