import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';
import TitleStyle from '../TitleStyle';

class GlancePage extends Component {
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Department at a glance`}
                    />

                    <br />

                    <Typography 
                        dangerouslySetInnerHTML={{ __html: this.props.data.department_about }}
                    style={{textAlign:'justify'}}>

                    </Typography>
                </Container>
            </div>
        );
    }
}

export default GlancePage;